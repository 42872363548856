import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { cleanNullProps } from '../../../utils/functions'
import Text from '../../typography/Text'
import Header from '../../typography/Header'

export type OrderCardInternationalPickupSummaryFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
  pickupLocation?: any
}

export const SHIPPING_METHOD = {
  FREE: 'Regular',
  RUSH_TRACK: 'Rush',
}

const schema = Yup.object().shape({
  arrivalDate: Yup.string(),
  deliveryMethod: Yup.string(),
  forUser: Yup.string(),
  pickupLocation: Yup.string(),
})

const OrderCardInternationalPickupSummaryForm: React.FC<
  OrderCardInternationalPickupSummaryFormProps
> = ({ data = {}, submit, loading, disabled, pickupLocation, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { sk, arrivalDate } = data

  const { name, country, address, city, postalCode, region } = pickupLocation

  const defaultInitialValues = {
    ...data,
  }

  const fullName = `${sk?.givenName} ${sk?.familyName}`

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      css={styles.form}
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <Header as="h3" css={styles.headline} token="ACCOUNT_HOLDER" />
      <Text css={styles.information}>{fullName}</Text>
      <Header as="h3" css={styles.headline} token="CARD_TYPE" />
      <Text css={styles.information} token="SIDEKICK_CARD_CANADA" />
      <Header as="h3" css={styles.headline} token="COLLECTION_ADDRESS" />
      <Text css={styles.information}>
        <Text as="div">{name}</Text>
        <Text as="div">{address}</Text>
        <Text as="div">{`${city}, ${region}`}</Text>
        <Text as="div">{`${country}, ${postalCode}`}</Text>
      </Text>

      {arrivalDate && (
        <>
          <Header
            as="h3"
            css={styles.headline}
            token="ACCOUNT_HOLDER_ARRIVAL_DATE_IN_CANADA"
          />
          <Text
            css={styles.information}
            date={arrivalDate}
            dateFormat="dd MMMM yyyy"
          />
        </>
      )}

      <FormFooter css={styles.footer}>
        <FormButton loading={loading} token="DONE" />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  information: {
    backgroundColor: BACKGROUND_ALT,
    borderRadius: 16,
    fontSize: '14px',
    margin: 0,
    padding: 24,
    '& > :first-child': {
      fontWeight: 700,
    },
  },
  headline: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '1px',
    margin: 0,
    padding: '0 16px',
    textTransform: 'uppercase',
  },
  form: {
    gridGap: 18,
  },
  label: {
    color: TEXT_PRIMARY_DARK,
    fontSize: '14px',
    letterSpacing: '-.25px',
    lineHeight: '20px',
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default OrderCardInternationalPickupSummaryForm
