import React from 'react'

import Actions from '../../components/Actions'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
import MainLayoutLink from '../../components/layout/MainLayoutLink'
import AccountCard from '../../components/cards/AccountCard'
import useIsInternationalUser from '../../hooks/useIsInternationalUser'
import useTeamMembers from '../../hooks/useTeamMembers'
import ResendEmailNotification from '../../components/ResendEmailNotification'
import VerifyIdBanner from '../../components/VerifyIdBanner'
import { DOCUMENT_TYPES } from '../../utils/docs'

type disableProps = {
  disableLink?: boolean
  disabled?: boolean
}

const AccountsScene = ({ ...rest }) => {
  const { loading, teamMembers, error } = useTeamMembers(true, true)
  const isInternational = useIsInternationalUser()

  return (
    <MainLayoutLink loading={loading} error={error} {...rest}>
      <Grid gap="1rem">
        <Actions feature="products.add">
          <Button
            token="ADD_PRODUCT"
            to="/products/new?from=/accounts"
            icon="plus"
            iconColor="white"
            size={1}
          />
        </Actions>

        {teamMembers &&
          teamMembers.reduce((acc, { userProfile, bankAccounts, ...rest }) => {
            if (bankAccounts.length > 0) {
              const disableProps: disableProps = {}

              if (isInternational) {
                disableProps.disableLink = disableProps.disabled =
                  !userProfile.isIdentityVerified
              }
              acc.push(
                <AccountCard
                  key={userProfile.id}
                  user={userProfile}
                  bankAccount={bankAccounts?.[0]}
                  to={`/accounts/${userProfile.id}`}
                  {...disableProps}
                />
              )
            }
            return acc
          }, [])}

        {teamMembers &&
          teamMembers.reduce((acc, { userProfile }) => {
            if (!userProfile.hasAcceptedInvitation) {
              acc.push(
                <ResendEmailNotification dismissable user={userProfile} />
              )
            }
            return acc
          }, [])}

        {isInternational &&
          teamMembers &&
          teamMembers.reduce((acc, { userProfile, userDocuments }) => {
            if (!userProfile.isIdentityVerified) {
              const id = userDocuments?.find(
                ({ type }) =>
                  type === DOCUMENT_TYPES.id || type === DOCUMENT_TYPES.passport
              )
              const letter = userDocuments?.find(
                ({ type }) => type === DOCUMENT_TYPES.letter
              )
              if (!id || !letter) {
                acc.push(<VerifyIdBanner dismissable user={userProfile} />)
              }
            }
            return acc
          }, [])}
      </Grid>
    </MainLayoutLink>
  )
}

export default AccountsScene
