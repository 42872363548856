import React, { PropsWithChildren } from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import FormContext from '../../../context/FormContext'
import { ColorType } from '../../../utils/themes'
import Flex from '../../Flex'
import Grid from '../../Grid'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'
import Icon from '../../Icon'
import Paragraph from '../../typography/Paragraph'

type DeliveryMethodOptionFieldProps = {
  value?: string | number
  user?: any
  name?: string
  className?: string
  disabled?: boolean
  color?: ColorType
} & PropsWithChildren

const DELIVERY = 'delivery'

const DeliveryMethodOptionField: React.FC<DeliveryMethodOptionFieldProps> = ({
  user,
  className,
  disabled,
  color = 'BLUE_200',
  value = 'DELIVERY',
  name,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const form = React.useContext<{ [key: string]: any }>(FormContext)
  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  const isDelivery = value === DELIVERY

  return (
    <Flex
      centerY
      gap="1rem"
      className={classNames}
      css={styles.root}
      onClick={async () => {
        await form?.setFieldValue(name, value)
        form?.submitForm()
      }}
      {...rest}
    >
      <IconCircle
        icon={isDelivery ? 'truck' : 'mapPin'}
        color={disabled ? theme.DISABLED_ICON_BACKGROUND : color}
        size={56}
        iconColor={theme.TEXT_PRIMARY}
      />

      <Grid gap={5} vertical stretch>
        <Text css={styles.name} token={isDelivery ? 'DELIVERY' : 'PICK_UP'} />
        <Paragraph
          css={styles.description}
          token={
            isDelivery
              ? 'MAILED_TO_YOUR_DOOR_VIA_CANADA_POST'
              : 'COLLECT_IT_FROM_A_NEARBY_LOCATION'
          }
        />
      </Grid>
      <Icon icon="chevron" color="BLACK_700" />
    </Flex>
  )
}

const computeStyles: TComputeStyles = ({
  DISABLED_BACKGROUND,
  TEXT_PRIMARY,
}: TTheme) => ({
  root: {
    borderRadius: '16px',
    padding: ' 16px 24px 16px 16px',
    '&.is-disabled': {
      backgroundColor: DISABLED_BACKGROUND,
    },
  },
  name: {
    color: TEXT_PRIMARY,
  },
  description: {
    color: TEXT_PRIMARY,
    fontSize: '14px',
    margin: 0,
  },
})

export default DeliveryMethodOptionField
