import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import ChooseCardInternationalDeliveryMethodForm from '../../components/form/forms/ChooseCardInternationalDeliveryMethodForm'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'

const DeliveryCardInternationalStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout>
      <Text token="HOW_WOULD_YOU_LIKE_TO_RECEIVE_THE_CARD" />
      <ChooseCardInternationalDeliveryMethodForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default DeliveryCardInternationalStep
