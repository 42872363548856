import React from 'react'

import IconCircle from './IconCircle'

const DOCUMENT_ICONS = {
  PASSPORT: 'globe',
  LETTER_OF_ACCEPTANCE: 'academic',
  ID_DOCUMENT: 'bank',
}

const DocumentIcon = ({ document, ...rest }) => {
  return <IconCircle icon={DOCUMENT_ICONS[document.type]} {...rest} />
}

export default DocumentIcon
