import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { DateTime } from 'luxon'
import Form from '../Form'
import DateField from '../fields/DateField'
import FormFooter from '../FormFooter'
import FormButton from '../Button'

type DateOfArrivalFormProps = {
  submit?: Function
  handleFieldUpdate?: Function
  loading?: boolean
  disabled?: boolean
  data?: object
}

const today = DateTime.now().toFormat('yyyy-MM-dd')

const schema = Yup.object().shape({
  arrivalDate: Yup.string()
    .test('AD', 'DATE_CANT_BE_LESS_THAN_TODAY', (value) => {
      return DateTime.fromISO(value).diffNow('days').days > -1
    })
    .required('THIS_IS_A_REQUIRED_FIELD'),
})

const defaultInitialValues = { arrivalDate: today }

const DateOfArrivalForm: React.FC<DateOfArrivalFormProps> = ({
  submit,
  loading,
  disabled,
  data,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ ...defaultInitialValues }}
      {...rest}
    >
      <DateField css={styles.field} name="arrivalDate" min={today} />
      <FormFooter css={styles.footer}>
        <FormButton token="NEXT" />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default DateOfArrivalForm
