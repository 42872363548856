import React from 'react'
import { Theme, useTheme } from '@emotion/react'
import { LinkProps } from 'react-router-dom'

import Icon from '../Icon'
import Card from './Card'
import LinkCard from './LinkCard'
import Text, { TranslationToken } from '../typography/Text'

import { IconType } from '../icons'
import { ColorType } from '../../utils/themes'
import clsx from 'clsx'

type ActionCardType = {
  icon: IconType
  title?: string
  subtitle?: string
  color?: ColorType | string
  iconColor?: ColorType | string
  token?: TranslationToken
  subToken?: TranslationToken
  date?: Date | string
  dateFormat?: string
  size?: number
  disabled?: boolean
  completed?: boolean
  className?: string
  hideIcon?: boolean
} & LinkProps

const ActionCard: React.FC<ActionCardType> = ({
  icon,
  title,
  subtitle,
  color,
  iconColor,
  token,
  subToken,
  date,
  dateFormat,
  size = 24,
  disabled,
  completed,
  className,
  to,
  hideIcon,
  ...rest
}) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme, color), [theme])

  const isDisabled = disabled || completed

  const classNames = clsx({
    [className]: className,
    'is-disabled': isDisabled,
  })

  const SelectedCard = to ? LinkCard : Card

  return (
    <SelectedCard
      className={classNames}
      css={styles.root}
      to={isDisabled ? null : to}
      {...rest}
    >
      {icon && (
        <div css={styles.circle}>
          <Icon icon={icon} size={size} color={iconColor} />
        </div>
      )}
      <div css={styles.content}>
        {date && (
          <Text
            date={date}
            dateFormat={dateFormat}
            css={styles.content.title}
          />
        )}

        {(title || token) && (
          <Text token={token} css={styles.content.title}>
            {title}
          </Text>
        )}
        {(subToken || subtitle) && (
          <Text token={subToken} css={styles.content.subtitle}>
            {subtitle}
          </Text>
        )}
      </div>
      {!hideIcon && <Icon icon={completed ? 'check' : 'chevron'} />}
    </SelectedCard>
  )
}

const computeStyles = (theme: Theme['theme'], color: ColorType | string) => {
  const { BACKGROUND_ALT, FIELD_DISABLED_BACKGROUND } = theme

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '1.3rem',

      backgroundColor: BACKGROUND_ALT,
      '&.is-disabled': {
        backgroundColor: FIELD_DISABLED_BACKGROUND,
        cursorPointer: 'none',
        pointerEvents: 'none',
      },
    },

    circle: {
      width: 56,
      height: 56,
      borderRadius: '50%',
      backgroundColor: theme[color] || color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0.5rem',
      flex: '1 auto',

      title: {
        fontWeight: 500,
      },

      subtitle: {
        fontSize: '.9rem',
      },
    },
  }
}

export default ActionCard
