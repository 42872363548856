import React from 'react'
import { useParams } from 'react-router'
import { TBreakpoins, TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { useLazyQuery } from '@apollo/client'

import { cardPin as cardIdQuery } from '../../../queries'
import Element from '../../../components/Element'
import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import Detail from '../../../components/typography/Detail'
import PhysicalCard from '../../../components/PhysicalCard'
import Button from '../../../components/Button'
import SceneZoomInAnimation from '../../../components/animations/SceneZoomInAnimation'

import useCardByUser from '../../../hooks/useCardByUser'
import Paragraph from '../../../components/typography/Paragraph'

const CardActivatedScene = () => {
  const { theme, breakpoints } = useTheme()
  const { id } = useParams()
  const styles = React.useMemo(() => computeStyles(theme, breakpoints), [theme])

  const { data: card, loading } = useCardByUser(id, {
    fetchPolicy: 'network-only',
  })

  const { id: cardId } = card || {}

  const [getPin, { data: pinData, loading: loadingPin }] =
    useLazyQuery(cardIdQuery)
  const { pin } = pinData?.cardPin || {}

  React.useEffect(() => {
    if (!pin && cardId)
      getPin({
        variables: {
          id: cardId,
        },
      })
  }, [pin, cardId])

  return (
    <SceneZoomInAnimation>
      <Flex vertical centerY css={styles.root}>
        <Flex vertical css={styles.container}>
          <Element width="100%" css={styles.block} />

          <Flex vertical gap="2rem" padding="2rem" css={styles.content} center>
            <Text token="CARD_ACTIVATED" center css={styles.header} />

            <Flex center>
              <PhysicalCard card={card} loading={loading || loadingPin} />
            </Flex>

            {pin && (
              <Paragraph
                css={styles.pin}
                token="YOUR_PIN_IS"
                interpolation={{ pin }}
              />
            )}

            <Flex padding="0 2rem" gap="1rem" css={{ maxWidth: 600 }}>
              <Text token="YOU_CAN_VIEW_YOUR_PIN_AT_ANY_TIME" center />
              <Detail token="ACTIVATE_CONTACTLESS_PAYMENTS" center />
            </Flex>

            <Flex>
              <Button to="/cards?refresh" token="CONFIRM" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </SceneZoomInAnimation>
  )
}

const computeStyles: TComputeStyles = (
  { TEXT_SECONDARY, BACKGROUND_ALT, CARD_PIN }: TTheme,
  { BREAKPOINT_SMALL, BREAKPOINT_DESKTOP }: TBreakpoins
) => ({
  root: {
    minHeight: '100vh',

    backgroundColor: BACKGROUND_ALT,
  },

  container: {
    maxWidth: 600,
  },

  header: {
    color: TEXT_SECONDARY,
    fontSize: 32,

    maxWidth: '50%',
    fontWeight: 'bold',
    fontFamily: 'Poppins',

    alignSelf: 'center',
  },

  block: {
    height: '30vh',
    background:
      'radial-gradient(155.31% 85.78% at 49.87% 85.78%, #49DCB1 0%, #4F76A1 59.8%, #0F3C70 100%)',

    [BREAKPOINT_DESKTOP]: {
      borderRadius: '0 0 1rem 1rem',
    },

    [BREAKPOINT_SMALL]: {
      borderRadius: 0,
    },
  },

  content: {
    transform: 'translateY(-25vh)',
    textAlign: 'center',

    [BREAKPOINT_SMALL]: {
      transform: 'translateY(-28vh)',
    },
  },

  pin: {
    color: CARD_PIN,
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: '24px',
    margin: 0,
    textAlign: 'center',
  },
})

export default CardActivatedScene
