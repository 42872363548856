import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { DateTime } from 'luxon'

import FormButton from '../Button'
import Form, { FormProps } from '../Form'
import FormFooter from '../FormFooter'
import Button from '../../Button'
import Field from '../Field'
import FieldCard from '../../cards/FieldCard'
import CountrySelectField from '../fields/CountrySelectField'
import StateSelectField from '../fields/StateSelectField'
import PasswordField from '../fields/PasswordField'
import FormHeader from '../../typography/FormHeader'

import FlowContext from '../../../context/FlowContext'
import {
  cleanNullProps,
  normalizeValidationErrorMessage,
} from '../../../utils/functions'
import { getStateAbbreviationByPretty } from '../../../utils/states'
import useCurrentUser from '../../../hooks/useCurrentUser'
import Text from '../../typography/Text'
import CreditCardField from '../fields/CreditCardField'

export type AddFundingMethodFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
} & FormProps

const schema = Yup.object().shape({
  newFundingMethod: Yup.object({
    name: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    brand: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    number: Yup.number().required('THIS_IS_A_REQUIRED_FIELD'),
    cvv: Yup.string().max(3).required('THIS_IS_A_REQUIRED_FIELD'),
    expiryMonth: Yup.number()
      .typeError('VALID_MONTH')
      .integer('VALID_MONTH')
      .min(1, 'VALID_MONTH')
      .max(12, 'VALID_MONTH')
      .required('THIS_IS_A_REQUIRED_FIELD'),
    expiryYear: Yup.number()
      .typeError('VALID_YEAR')
      .integer('VALID_YEAR')
      .min(
        DateTime.now().year,
        normalizeValidationErrorMessage('YEAR_GREATER_THAN', {
          minimum: DateTime.now().year,
        })
      )
      .max(
        DateTime.now().year + 5,
        normalizeValidationErrorMessage('YEAR_LESS_THAN', {
          maximum: DateTime.now().year + 5,
        })
      )
      .required('THIS_IS_A_REQUIRED_FIELD'),
  }),

  personalInfo: Yup.object({
    firstName: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    lastName: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    addressLine1: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    addresLine2: Yup.string(),
    addressCity: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    addressCountry: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    addressPostalCode: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    addressState: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
  }),
})

const AddFundingMethodForm: React.FC<AddFundingMethodFormProps> = ({
  submit,
  disabled,
  loading,
  initialValues,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { prev } = React.useContext(FlowContext)
  const { data: user } = useCurrentUser()

  const profile = user?.userProfile

  const defaultInitialValues = {
    ...initialValues,

    newFundingMethod: {
      brand: 'visa',
    },

    personalInfo: {
      firstName: profile?.givenName,
      lastName: profile?.familyName,
      addressLine1: profile?.principalAddress?.addressLine1,
      addressLine2: profile?.principalAddress?.addressLine2,
      addressCity: profile?.principalAddress?.city,
      addressCountry: 'Canada',
      addressPostalCode: profile?.principalAddress?.postalCode,
      addressState: getStateAbbreviationByPretty(
        profile?.principalAddress?.region
      ),
    },
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      disabled={disabled}
      css={styles.root}
      {...rest}
    >
      <FormHeader token="PAYMENT_DETAILS" />
      <FieldCard label={<Text token="CARD_DETAILS" />}>
        <CreditCardField
          label={<Text token="CARD_NUMBER" />}
          name="newFundingMethod.number"
          required
        />

        <Field
          label={<Text token="NAME_ON_CARD" />}
          name="newFundingMethod.name"
          required
        />

        <Field
          label={<Text token="EXPIRATION_MONTH" />}
          name="newFundingMethod.expiryMonth"
          maxLength={2}
          placeholder="MM"
          required
        />
        <Field
          label={<Text token="EXPIRATION_YEAR" />}
          name="newFundingMethod.expiryYear"
          maxLength={4}
          placeholder="YYYY"
          required
        />

        <PasswordField
          label={<Text token="CVV" />}
          name="newFundingMethod.cvv"
          maxLength="3"
          required
        />
      </FieldCard>

      <FormHeader token="CARDHOLDER_DETAILS" />

      <FieldCard label={<Text token="PERSONAL_INFO" />}>
        <Field
          name="personalInfo.firstName"
          label={<Text token="FIRST_NAME" />}
          required
        />
        <Field
          name="personalInfo.lastName"
          label={<Text token="LAST_NAME" />}
          required
        />
        <Field
          name="personalInfo.addressLine1"
          label={<Text token="ADDRESS_LINE_1" />}
          required
        />
        <Field
          name="personalInfo.addressLine2"
          label={<Text token="ADDRESS_LINE_2" />}
        />
        <Field
          name="personalInfo.addressCity"
          label={<Text token="CITY" />}
          required
        />
        <StateSelectField
          name="personalInfo.addressState"
          label={<Text token="STATE" />}
          required
        />
        <Field
          name="personalInfo.addressPostalCode"
          label={<Text token="POSTAL_OR_ZIP_CODE" />}
          required
        />
        <CountrySelectField
          name="personalInfo.addressCountry"
          label={<Text token="COUNTRY" />}
          required
          disabled
        />
      </FieldCard>

      <FormFooter css={styles.footer}>
        <Button
          button
          token="PREVIOUS_STEP"
          onClick={prev}
          disabled={disabled}
        />
        <FormButton token="NEXT" disabled={disabled} />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
}: TTheme) => ({
  root: { width: '100%' },
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default AddFundingMethodForm
