import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Session from '../Session'
import MainLayout from '../layout/MainLayout'
import CardLayout from '../layout/CardLayout'
import PrivateRoute from './PrivateRoute'
import Status404 from '../Status404'
import InitialCall from '../InitialCall'
import Text from '../typography/Text'

import LoginScene from '../../scenes/auth/login'
import LoginTokenScene from '../../scenes/auth/token'
import DashboardScene from '../../scenes/dashboard/dashboard'
import SettingsScene from '../../scenes/settings/settings'
import SettingsProfileScene from '../../scenes/settings/profile'
import SettingsTeamScene from '../../scenes/settings/team/team'
import ManageUserDocumentsScene from '../../scenes/settings/documents/documents'
import UploadUserDocumentsScene from '../../scenes/settings/documents/upload'
import KYC from '../../scenes/settings/team/flows/kyc/kyc'
import AccountsScene from '../../scenes/accounts/accounts'
import AccountScene from '../../scenes/accounts/account'
import MyAccountScene from '../../scenes/accounts/my-account'
import CardsScene from '../../scenes/cards/cards'
import ProductsScene from '../../scenes/products/products'
import SettingsMemberPersonalInformationScene from '../../scenes/settings/members/personal-information'
import SettingsProfilePersonalInformationScene from '../../scenes/settings/profile/personal-information'
import SubscriptionsScene from '../../scenes/settings/subscriptions/subscriptions-scene'
import SubscriptionsCancelScene from '../../scenes/settings/subscriptions/subscriptions-cancel'
import SubscriptionsInvoices from '../../scenes/settings/subscriptions/invoices/subscriptions-invoices'
import SubscriptionsInvoiceDetail from '../../scenes/settings/subscriptions/invoices/subscriptions-invoice-detail'
import SubscriptionsFlow from '../../scenes/settings/subscriptions/subscriptions-flow'
import SubscriptionDeeplink from '../../scenes/settings/subscriptions/subscriptions-deeplink'
import AddTeamMemberFlow from '../../scenes/settings/team/flows/add-team-member/add-team-member'
import AddProductFlow from '../../scenes/products/add-product/add-product'
import OrderCardFlow from '../../scenes/cards/order-card'
import OrderCardReplaceFlow from '../../scenes/cards/flows/order-card-replacement/order-card-replacement'
import PublicLayout from '../layout/PublicLayout'
import RegisterScene from '../../scenes/auth/register/register'
import AcceptedTOSRoute from './AcceptedTOSRoute'
import AcceptTOSScene from '../../scenes/termsOfServices/acceptTOS'
import LogoutScene from '../../scenes/auth/logout'
import AcceptInviteScene from '../../scenes/auth/register/accept-invite'
import ChangePasswordScene from '../../scenes/settings/profile/changePassword'
import LoginDetailsScene from '../../scenes/settings/profile/loginDetails'
import PasswordResetScene from '../../scenes/auth/passwordReset'
import NewPasswordScene from '../../scenes/auth/newPassword'
import NotificationsSettingsScene from '../../scenes/settings/notifications/notificationsSettings'
import NotificationsRoute from './NotificationsRoute'
import NotificationsScene from '../../scenes/notifications/notifications'
import AllocationsScene from '../../scenes/allocations/allocations-scene'
import EnterActivationCodeScene from '../../scenes/cards/activation/enter-activation-code'
import CardActivatedScene from '../../scenes/cards/activation/card-activated'
import FreezeCardScene from '../../scenes/cards/freeze/freeze'
import CardTransactionLimitsScene from '../../scenes/cards/transaction-limits/transaction-limits'
import RetrievePinScene from '../../scenes/cards/retrieve-pin/retrieve-pin'
import CardDetailsScene from '../../scenes/cards/details/details'
import BlocklistScene from '../../scenes/cards/blocklist/blocklist'
import FundAccountScene from '../../scenes/fund-account/fund-account'
import PermissionsScene from '../../scenes/settings/permissions/permissions'
import ContactUscene from '../../scenes/settings/contact-us/contactUs'
import ReallocateScene from '../../scenes/reallocate/reallocate-scene'
import TOSScene from '../../scenes/settings/termsOfServices/tos'
import EmailConfirmationScene from '../../scenes/emailConfirmation/emailConfirmation'
import RecurringAllowancesScene from '../../scenes/settings/recurring-allowances/recurring-allowances'
import NewRecurringAllowanceScene from '../../scenes/settings/recurring-allowances/new-recurring-allowance'
import EditRecurringAllowanceScene from '../../scenes/settings/recurring-allowances/edit-recurring-allowance'
import AboutCategoriesScene from '../../scenes/allocations/about-categories'

const ApplicationRouter = () => (
  <Routes>
    <Route path="/" element={<Session />}>
      {/* Public Routes */}

      <Route path="logout" element={<LogoutScene />} />

      <Route path="/" element={<CardLayout />}>
        <Route
          path="contact-us"
          element={
            <ContactUscene
              title={<Text token="CONTACT_US" />}
              subtitle={<Text token="SUPPORT" />}
              back="/"
            />
          }
        />
      </Route>

      <Route path="auth" element={<PublicLayout />}>
        <Route path="*" element={<Status404 />} />
        <Route path="login" element={<LoginScene />} />
        <Route path="tokenLogin" element={<LoginTokenScene />}></Route>
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="email-confirmation" element={<EmailConfirmationScene />} />
      </Route>

      <Route path="auth" element={<CardLayout />}>
        <Route path="*" element={<Status404 />} />
        <Route path="register" element={<RegisterScene />} />
        <Route path="password" element={<AcceptInviteScene />} />
        <Route
          path="reset-password"
          element={
            <PasswordResetScene title={<Text token="RESET_PASSWORD" />} />
          }
        />
        <Route
          path="new-password"
          element={
            <NewPasswordScene title={<Text token="CREATE_A_NEW_PASSWORD" />} />
          }
        />
      </Route>
      <Route path="onboarding" element={<CardLayout />}>
        <Route path="*" element={<Status404 />} />
        <Route
          path="accept-tos"
          element={
            <AcceptTOSScene
              title={<Text token="TERMS_OF_SERVICE" />}
              cancel="/logout"
            />
          }
        />
      </Route>

      {/* Private Routes */}

      <Route element={<PrivateRoute />}>
        <Route element={<AcceptedTOSRoute />}>
          <Route element={<InitialCall />}>
            <Route path="/" element={<NotificationsRoute />}>
              <Route path="*" element={<Status404 />} />
              <Route
                path="/cards/activate/:id/success"
                element={<CardActivatedScene />}
              />

              <Route path="/" element={<MainLayout />}>
                <Route index element={<DashboardScene />} />
                <Route path="accounts" element={<AccountsScene />} />
                <Route path="cards" element={<CardsScene />} />
                <Route path="products" element={<ProductsScene />} />
              </Route>

              <Route element={<CardLayout />}>
                <Route
                  path="allocate"
                  element={
                    <AllocationsScene title={<Text token="ALLOCATE" />} />
                  }
                />
                <Route
                  path="re-allocate/:id"
                  element={
                    <ReallocateScene title={<Text token="REALLOCATE" />} />
                  }
                />
              </Route>

              <Route path="add-funds" element={<CardLayout />}>
                <Route
                  index
                  element={
                    <FundAccountScene title={<Text token="ADD_FUNDS" />} />
                  }
                />
              </Route>

              <Route path="notifications" element={<CardLayout />}>
                <Route
                  index
                  element={
                    <NotificationsScene
                      title={<Text token="NOTIFICATIONS" />}
                      back="/"
                    />
                  }
                />
              </Route>

              <Route path="cards" element={<CardLayout />}>
                <Route
                  path="activate/:id"
                  element={<EnterActivationCodeScene back="/cards" />}
                />
                <Route
                  path="freeze/:id"
                  element={<FreezeCardScene back="/cards" />}
                />
                <Route
                  path="transaction-limits/:id"
                  element={<CardTransactionLimitsScene back="/cards" />}
                />
                <Route
                  path="retrieve-pin/:id"
                  element={<RetrievePinScene back="/cards" />}
                />
                <Route
                  path="details/:id"
                  element={<CardDetailsScene back="/cards" />}
                />
                <Route
                  path="blocklist/:id"
                  element={<BlocklistScene back="/cards" />}
                />
                <Route path="order" element={<OrderCardFlow />}>
                  <Route path=":id" element={<OrderCardFlow />} />
                </Route>
                <Route path="replace/:id" element={<OrderCardReplaceFlow />} />
              </Route>

              <Route path="products" element={<CardLayout />}>
                <Route path="new" element={<AddProductFlow />} />
              </Route>

              <Route path="account" element={<CardLayout />}>
                <Route index element={<MyAccountScene back="/" />} />
              </Route>

              <Route path="accounts" element={<CardLayout />}>
                <Route
                  path=":id"
                  element={
                    <AccountScene
                      title={<Text token="SIDEKICK_ACCOUNTS" />}
                      back="/accounts"
                    />
                  }
                />
              </Route>
              <Route path="settings" element={<CardLayout checkedSteps />}>
                <Route
                  index
                  element={
                    <SettingsScene title={<Text token="SETTINGS" />} back="/" />
                  }
                />

                <Route path="subscriptions">
                  <Route
                    index
                    element={
                      <SubscriptionsScene
                        subtitle={<Text token="SETTINGS" />}
                        title={<Text token="MANAGE_SUBSCRIPTIONS" />}
                        back="/settings"
                      />
                    }
                  />
                  <Route
                    path="mobile"
                    element={
                      <SubscriptionsScene
                        fromMobile
                        subtitle={<Text token="SETTINGS" />}
                        title={<Text token="MANAGE_SUBSCRIPTIONS" />}
                        back="/settings"
                      />
                    }
                  />
                  <Route
                    path="deeplink"
                    element={
                      <SubscriptionDeeplink
                        subtitle={<Text token="SETTINGS" />}
                        title={<Text token="MANAGE_SUBSCRIPTIONS" />}
                        back="/settings"
                      />
                    }
                  />
                  <Route
                    path="edit"
                    element={
                      <SubscriptionsFlow
                        edit
                        title={<Text token="EDIT_PLAN" />}
                      />
                    }
                  />
                  <Route
                    path="edit-mobile"
                    element={
                      <SubscriptionsFlow
                        edit
                        fromMobile
                        title={<Text token="EDIT_PLAN" />}
                      />
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <SubscriptionsFlow title={<Text token="ADD_PLAN" />} />
                    }
                  />

                  <Route
                    path="cancel"
                    element={
                      <SubscriptionsCancelScene
                        title={<Text token="CANCEL_PLAN" />}
                        cancel="/settings/subscriptions"
                      />
                    }
                  />
                  <Route path="invoices">
                    <Route
                      index
                      element={
                        <SubscriptionsInvoices
                          title={<Text token="INVOICES" />}
                          subtitle={<Text token="MANAGE_SUBSCRIPTIONS" />}
                          back="/settings/subscriptions"
                        />
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <SubscriptionsInvoiceDetail
                          title={<Text token="INVOICE" />}
                          subtitle={<Text token="MANAGE_SUBSCRIPTIONS" />}
                          back="/settings/subscriptions/invoices"
                        />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="kyc"
                  element={<KYC title={<Text token="ID_VERIFICATION" />} />}
                />

                <Route path="recurring-allowance">
                  <Route
                    index
                    element={
                      <RecurringAllowancesScene
                        title={<Text token="RECURRING_ALLOWANCE" />}
                        back="/account?tab=all"
                      />
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <NewRecurringAllowanceScene
                        title={<Text token="RECURRING_ALLOWANCE" />}
                        back="/settings/recurring-allowance"
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <EditRecurringAllowanceScene
                        title={<Text token="RECURRING_ALLOWANCE" />}
                        back="/settings/recurring-allowance"
                      />
                    }
                  />
                </Route>

                <Route path="profile">
                  <Route
                    index
                    element={
                      <SettingsProfileScene
                        title={<Text token="USER_PROFILE" />}
                        subtitle={<Text token="SETTINGS" />}
                        back="/settings"
                      />
                    }
                  />

                  <Route path="documents">
                    <Route
                      index
                      element={
                        <ManageUserDocumentsScene
                          subtitle={<Text token="SETTINGS" />}
                          title={<Text token="MANAGE_USER_DOCUMENTS" />}
                          back="/settings"
                        />
                      }
                    />

                    <Route
                      path="upload"
                      element={
                        <UploadUserDocumentsScene
                          subtitle={<Text token="SETTINGS" />}
                          title={<Text token="UPLOAD_USER_DOCUMENTS" />}
                          back="/settings/profile/documents"
                        />
                      }
                    />
                  </Route>

                  <Route
                    path="personal-information"
                    element={
                      <SettingsProfilePersonalInformationScene
                        title={<Text token="USER_PROFILE" />}
                        subtitle={<Text token="SETTINGS" />}
                        back="/settings/profile"
                      />
                    }
                  />
                  <Route
                    path="change-password"
                    element={
                      <ChangePasswordScene
                        title={<Text token="RESET_PASSWORD" />}
                        back="/settings/profile"
                        cancel="/settings/profile"
                      />
                    }
                  />

                  <Route
                    path="login-details"
                    element={<LoginDetailsScene back="/settings/profile" />}
                  />
                </Route>

                <Route
                  path="permissions"
                  element={
                    <PermissionsScene
                      title={<Text token="PERMISSIONS" />}
                      subtitle={<Text token="SETTINGS" />}
                      back="/settings"
                    />
                  }
                />

                <Route
                  path="notifications"
                  element={<NotificationsSettingsScene back="/settings" />}
                />

                <Route path="team">
                  <Route
                    index
                    element={
                      <SettingsTeamScene
                        title={<Text token="MANAGE_MY_TEAM" />}
                        subtitle={<Text token="SETTINGS" />}
                        back="/settings"
                      />
                    }
                  />

                  <Route
                    path="add-team-member"
                    element={
                      <AddTeamMemberFlow
                        title={<Text token="ADD_TEAM_MEMBER" />}
                      />
                    }
                  />

                  <Route path=":id">
                    <Route
                      path="personal-information"
                      element={
                        <SettingsMemberPersonalInformationScene
                          title={<Text token="PERSONAL_INFORMATION" />}
                          subtitle={<Text token="SETTINGS" />}
                          back="/settings/team"
                        />
                      }
                    />
                    <Route
                      path="login-details"
                      element={<LoginDetailsScene back="/settings/team" />}
                    />

                    <Route path="documents">
                      <Route
                        index
                        element={
                          <ManageUserDocumentsScene
                            subtitle={<Text token="SETTINGS" />}
                            title={<Text token="MANAGE_USER_DOCUMENTS" />}
                            back
                          />
                        }
                      />

                      <Route
                        path="upload"
                        element={
                          <UploadUserDocumentsScene
                            subtitle={<Text token="SETTINGS" />}
                            title={<Text token="UPLOAD_USER_DOCUMENTS" />}
                            back
                          />
                        }
                      />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="contact-us"
                  element={
                    <ContactUscene
                      title={<Text token="CONTACT_US" />}
                      subtitle={<Text token="SUPPORT" />}
                      back="/settings"
                    />
                  }
                />

                <Route
                  path="tos"
                  element={
                    <TOSScene
                      title={<Text token="TERMS_OF_SERVICE" />}
                      back="/settings"
                      cancel="/settings"
                    />
                  }
                />
              </Route>
              <Route path="about-categories" element={<CardLayout />}>
                <Route
                  index
                  element={
                    <AboutCategoriesScene
                      title={<Text token="ABOUT_CATEGORIES" />}
                      back="/accounts"
                    />
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Routes>
)

export default ApplicationRouter
