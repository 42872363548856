import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import Element from './Element'

import Info from './Info'
import InfoError from './InfoError'
import InfoSuccess from './InfoSuccess'
import { TranslationToken } from './typography/Text'

const HeroToaster = ({ safeArea, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const getToast = (message: string, type: string) => {
    switch (type) {
      case 'error':
        return <InfoError token={message as TranslationToken} dismissable />
      case 'success':
        return <InfoSuccess token={message as TranslationToken} dismissable />
      default:
        return <Info token={message as TranslationToken} dismissable />
    }
  }

  return (
    <Toaster
      containerStyle={{ top: safeArea?.top || 20 }}
      position="top-center"
    >
      {(t) => {
        const message =
          typeof t.message === 'string' && t.message.replaceAll('"', '')
        return (
          <AnimatePresence>
            {t.visible && (
              <Element
                css={styles.root}
                initial="hidden"
                exit="hidden"
                animate="visible"
                variants={{
                  hidden: { opacity: 0, y: -20, transition: { duration: 0.5 } },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                }}
              >
                {getToast(message, t.type)}
              </Element>
            )}
          </AnimatePresence>
        )
      }}
    </Toaster>
  )
}

const computeStyles: TComputeStyles = ({ NOTIFICATION_SHADOW }: TTheme) => ({
  root: {
    boxShadow: `${NOTIFICATION_SHADOW} 0px 0px 5px`,
  },
})

export default HeroToaster
