import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { DateTime } from 'luxon'
import Form from '../Form'
import DateField from '../fields/DateField'
import FormFooter from '../FormFooter'
import FormButton from '../Button'

type CardholderArrivalDateFormProps = {
  submit?: Function
  handleFieldUpdate?: Function
  loading?: boolean
  disabled?: boolean
  data?: object
}

const tomorrow = DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')

const schema = Yup.object().shape({
  arrivalDate: Yup.string()
    .test('AD', 'ARRIVAL_DATE_CANT_BE_LESS_THAN_TOMORROW', (value) => {
      return DateTime.fromISO(value).diffNow('days').days > 0
    })
    .required('THIS_IS_A_REQUIRED_FIELD'),
})

const defaultInitialValues = { arrivalDate: tomorrow }

const CardholderArrivalDateForm: React.FC<CardholderArrivalDateFormProps> = ({
  submit,
  loading,
  disabled,
  data,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ ...defaultInitialValues }}
      {...rest}
    >
      <DateField css={styles.field} name="arrivalDate" min={tomorrow} />
      <FormFooter css={styles.footer}>
        <FormButton token="NEXT" />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default CardholderArrivalDateForm
