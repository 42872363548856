import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import ThanksOrderForm from '../../components/form/forms/ThanksOrderForm'
import Paragraph from '../../components/typography/Paragraph'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'
import { findOpenCardPickups, findPickupLocations } from '../../queries'
import { DateTime } from 'luxon'

const InternationalpickupThanks = ({ ...rest }) => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { currentData, setFlowData } = React.useContext(FlowContext)
  const { refetch } = useQuery(findOpenCardPickups)

  const { data: pickupLocationsData, loading } = useQuery(findPickupLocations)

  const { pickupLocations = [] } = pickupLocationsData || {}

  const { arrivalDate, pickupLocation } = currentData.data

  const location = pickupLocations.find((loc) => loc.code === pickupLocation)

  const handleSubmit = async () => {
    await refetch()
    navigate('/cards')
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })
  return (
    <FlowStepLayout loading={loading}>
      <Text
        css={styles.legend}
        token="YOUR_SIDEKICK_CARD_CAN_BE_PICKED_UP_AT"
        interpolation={{
          date: DateTime.fromISO(arrivalDate).toFormat('dd MMMM yyyy'),
        }}
      />
      <Paragraph>
        <Text css={styles.legend} as="div">
          {location.name}
        </Text>
        <Text css={styles.legend} as="div">
          {location.address}
        </Text>

        <Text css={styles.legend} as="div">
          {`${location.city}, ${location.region}`}
        </Text>

        <Text css={styles.legend} as="div">
          {`${location.country}, ${location.postalCode}`}
        </Text>
      </Paragraph>

      <ThanksOrderForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        {...rest}
      />
    </FlowStepLayout>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY, LINK }: TTheme) => ({
  root: {},
  legend: {
    fontSize: '14px',
    color: TEXT_PRIMARY,
  },
  emailUs: {
    background: 'none',
    color: LINK,
    width: 'min-content',
    padding: 0,
  },
})

export default InternationalpickupThanks
