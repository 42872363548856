import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { NavLink, Link, NavLinkProps, useMatch } from 'react-router-dom'
import clsx from 'clsx'

import Icon, { IconProps } from './Icon'
import Spinner from './Spinner'
import Text, { TextProps, TranslationToken } from './typography/Text'
import Element from './Element'

import withFeature from '../hocs/withFeature'

type styleType = 'default' | 'error' // | 'success' | 'warning'

export type ButtonProps = {
  label?: string | JSX.Element
  nav?: boolean
  button?: boolean
  loading?: boolean
  disabled?: boolean
  iconColor?: string
  to?: string
  capitalize?: boolean
  uppercase?: boolean
  token?: TranslationToken
  styleType?: styleType
  textProps?: TextProps
  className?: string
  secondary?: boolean
} & Omit<NavLinkProps, 'to'> &
  IconProps

const HTMLButtonComponent = (props) => <Element as="button" {...props} />

const Button: React.FC<ButtonProps> = ({
  label,
  button = false,
  nav = false,
  icon,
  size = 18,
  loading = false,
  disabled = false,
  iconColor,
  token,
  children,
  capitalize,
  uppercase,
  className,
  textProps,
  styleType = 'default',
  secondary,
  ...rest
}) => {
  const { to } = rest
  const active = useMatch(to || '')
  const { theme, colors } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { BLACK_600, BLACK_900, WHITE } = colors

  const NavComponent = nav ? NavLink : Link
  const ButtonComponent = button ? HTMLButtonComponent : NavComponent
  const activeIconColor = iconColor || (active ? BLACK_900 : BLACK_600)

  const classNames = clsx({
    [className]: className,
    'is-loading': loading,
    'is-disabled': disabled,
    'is-error': styleType === 'error',
    'is-secondary': secondary,
  })

  return (
    <ButtonComponent css={styles.root} className={classNames} {...rest}>
      {!loading && (
        <>
          {icon && (
            <Icon
              icon={icon}
              size={size}
              color={disabled ? BLACK_600 : activeIconColor}
              css={{ marginRight: label || token ? 10 : 0 }}
            />
          )}

          <Text
            token={token}
            uppercase={uppercase}
            capitalize={capitalize}
            {...textProps}
          >
            {label && label}
          </Text>
        </>
      )}

      {loading && <Spinner size={20} color={iconColor || WHITE} />}

      {children && children}
    </ButtonComponent>
  )
}

const computeStyles: TComputeStyles = ({
  BUTTON_ACTIVE_BACKGROUND,
  DISABLED_BACKGROUND,
  DISABLED,
  ERROR_ALT,
  PRIMARY,
  TEXT_PRIMARY_DARK,
  TEXT_SECONDARY,
}: TTheme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: PRIMARY,
    border: 0,
    borderRadius: 16,
    color: TEXT_SECONDARY,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 400,
    justifyContent: 'center',
    padding: '12px 16px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    '&.is-secondary': {
      backgroundColor: 'transparent',
      color: PRIMARY,
    },
    '&.active': {
      backgroundColor: BUTTON_ACTIVE_BACKGROUND,
      color: TEXT_PRIMARY_DARK,
    },

    '&.is-error': {
      backgroundColor: ERROR_ALT,
      color: TEXT_SECONDARY,
    },

    '&.is-loading, &.is-disabled': {
      backgroundColor: DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },
  },
})

export default withFeature(Button)
