import React from 'react'
import { useQuery } from '@apollo/client'
import { Theme, useTheme } from '@emotion/react'

import Card, { CardProps } from './Card'
import CardTitle from './CardTitle'
import ActionCard from './ActionCard'

import { FeatureProps } from '../../hooks/useFeature'
import withFeature from '../../hocs/withFeature'
import { getCards } from '../../queries'
import useTeamMembers from '../../hooks/useTeamMembers'
import useCurrentUser from '../../hooks/useCurrentUser'
import useTeamHoldingAccount from '../../hooks/useTeamHoldingsAccount'
import { useIsValidSubscription } from '../../hooks/useSubscriptions'
import KYCSTatusType from '../../utils/kyc-status'

import useIsInternationalUser from '../../hooks/useIsInternationalUser'

export type FirstStepsCardProps = {} & CardProps & FeatureProps

const FirstStepsCard: React.FC<FirstStepsCardProps> = ({
  feature = 'firstSteps',
  ...rest
}) => {
  const { loading: cardsLoading, error: cardsError } = useQuery(getCards)
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const {
    loading: loadingMembers,
    teamMembers,
    error: membersError,
  } = useTeamMembers(true, true)

  const isInternational = useIsInternationalUser()

  const { data: user, loading: userLoading } = useCurrentUser(true)

  const { data: teamHoldingData, loading: holdingLoading } =
    useTeamHoldingAccount(true)

  const { data: subscriptionValidData, loading: subscriptionValidLoading } =
    useIsValidSubscription()

  const hasSubscriptionPlan = subscriptionValidData?.isValid

  const hasProduct = teamMembers.length > 0

  const isKYCVerified =
    user?.userProfile?.kycValidationStatus === KYCSTatusType.Approved
  const isKYCPending =
    user?.userProfile?.kycValidationStatus === KYCSTatusType.Pending
  const isFirstFundAdded =
    teamHoldingData?.teamHoldingAccount?.holdingAccountTransactions?.edges
      ?.length > 0
  const hasAllocated = teamMembers.some((sk) => {
    return sk?.bankAccounts?.[0]?.transactions?.edges?.length > 0
  })

  const isOnboardFinished =
    hasProduct &&
    (isKYCVerified || isKYCPending) &&
    isFirstFundAdded &&
    hasAllocated

  const loading =
    cardsLoading ||
    userLoading ||
    holdingLoading ||
    subscriptionValidLoading ||
    loadingMembers

  const canAllocateInternational = isInternational
    ? teamMembers.filter(({ userProfile }) => userProfile.isIdentityVerified)
        ?.length
    : true

  const error = cardsError || membersError
  return (
    !isOnboardFinished && (
      <Card
        feature="firstSteps"
        loading={loading}
        error={error}
        css={styles.root}
        {...rest}
      >
        <CardTitle token="GET_STARTED" />

        <ActionCard
          icon="card"
          completed={hasProduct}
          token="ADD_A_PRODUCT"
          subToken="SUBSCRIBE_TO_A_PLAN"
          color={theme.GET_STARTED_ADD_PRODUCT}
          iconColor={theme.GET_STARTED_ICON_COLOR}
          to="/products/new"
          size={1}
        />
        <ActionCard
          icon="id"
          hideIcon={isKYCPending}
          completed={isKYCVerified || isKYCPending}
          token="VERIFY_YOUR_IDENTITY"
          subToken="ID_PROFILE_VERIFICATION"
          color={theme.GET_STARTED_IDENTIFY}
          iconColor={theme.GET_STARTED_ICON_COLOR}
          to="/settings/kyc"
          size={1}
        />

        <ActionCard
          icon="bankNote"
          completed={isFirstFundAdded}
          token="ADD_FUNDS"
          subToken="USING_YOUR_PREFERRED_METHOD"
          color={theme.GET_STARTED_ADD_FUNDS}
          iconColor={theme.GET_STARTED_ICON_COLOR}
          to="/add-funds"
          size={1}
          disabled={!hasSubscriptionPlan}
        />

        <ActionCard
          icon="transaction"
          completed={hasAllocated}
          token="MAKE_YOUR_FIRST_ALLOCATION"
          subToken="AND_YOU_ARE_ALL_SET"
          color={theme.GET_STARTED_FIRST_ALLOCATION}
          iconColor={theme.GET_STARTED_ICON_COLOR}
          to="/allocate"
          size={1}
          disabled={!hasSubscriptionPlan || !canAllocateInternational}
        />
      </Card>
    )
  )
}

const computeStyles = ({ GET_STARTED_BACKGROUND }: Theme['theme']) => ({
  root: {
    backgroundColor: GET_STARTED_BACKGROUND,
    minHeight: '10rem',
  },
})

export default withFeature(FirstStepsCard, 'firstSteps')
