import { useTheme } from '@emotion/react'
import React from 'react'

import Banner, { Props as BannerProps } from './Banner'

const InfoError: React.FC<BannerProps> = ({ ...rest }) => {
  const { theme } = useTheme()
  return <Banner icon="error" color={theme.ERROR} {...rest} />
}

export default InfoError
