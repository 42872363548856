import React from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import FlowCardLayout, {
  FlowStep,
} from '../../components/layout/FlowCardLayout'
import Text from '../../components/typography/Text'
import ConfirmModal from '../../components/modals/ConfirmModal'
import FundAccountInputAmountStep from './fund-input-amount'
import FundAccountAddFundingMethodStep from './fund-add-funding-method'
import FundAccountSelectFundingMethodStep from './fund-select-funding-method'
import FundAccount3DSAuth from './fund-account-3ds-auth'
import FundAccountConfirmPaymentStep from './fund-confirm-payment'
import FundAccountStatusStep from './fund-account-status'

import usePrompt from '../../hooks/usePrompt'
import { fundingSources } from '../../queries'
import { FlowStateType } from '../../store/flow'

const FundAccountScene = ({ ...rest }) => {
  const flowState = useSelector(
    (state: FlowStateType) => state.flow.fundAccount
  )
  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta?.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)

  const { data: fundingSourcesData, loading } = useQuery(fundingSources)

  React.useEffect(() => {
    if (
      flowState?.meta?.keepDirty &&
      fundingSourcesData?.fundingSources?.length === 0
    )
      if (flag) setShowConfirm(flag)
  }, [flag])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  return (
    <>
      <FlowCardLayout name="fundAccount" loading={loading} cancel="/" {...rest}>
        <FlowStep element={() => <FundAccountInputAmountStep />} />
        <FlowStep
          element={() => (
            <FundAccountSelectFundingMethodStep
              fundingSources={fundingSourcesData?.fundingSources}
            />
          )}
        />
        <FlowStep
          element={() => <FundAccountAddFundingMethodStep />}
          condition={(data) =>
            data?.selectedFundingMethod === 'new' ||
            fundingSourcesData?.fundingSources?.length === 0 ||
            data?.isNewFundingSource
          }
        />
        <FlowStep
          condition={(data) => data?.fundingSourceModel?.with3DSChallenge}
          element={() => <FundAccount3DSAuth />}
        />
        <FlowStep element={() => <FundAccountConfirmPaymentStep />} />
        <FlowStep element={() => <FundAccountStatusStep />} />
      </FlowCardLayout>

      {showConfirm && (
        <ConfirmModal
          title={<Text token="CONFIRM" />}
          content={<Text token="DATA_LOSS_CONFIRM_MESSAGE" />}
          confirm={next}
          cancel={closeModal}
          backdrop
        />
      )}
    </>
  )
}

export default FundAccountScene
