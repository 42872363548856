import React from 'react'

import Pill, { PillProps } from './Pill'

const STATUSES = {
  Pending: 'warning-alt',
  Verified: 'primary-alt',
  Declined: 'warning',
}

const DocumentStatusPill: React.FC<PillProps> = ({ status, ...rest }) => {
  return (
    <Pill variant={STATUSES[status]} {...rest}>
      {status}
    </Pill>
  )
}

export default DocumentStatusPill
