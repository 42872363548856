import { generateUUID } from '../utils/functions'

export const PREFIX_3DS = '3ds-'
export const PREFIX_3DS_CHALLENGE = '3ds-challenge-'
export const ID_3DS_WRAPPER = '3ds-wrapper'

export enum STATUS_3DS {
  challenge_required = 'challenge_required',
  authenticated = 'authenticated',
  fetch_updates_failed = 'fetch_updates_failed',
  authentication_attempt_acknowledged = 'authentication_attempt_acknowledged',
  not_authenticated = 'not_authenticated',
  authentication_failed = 'authentication_failed',
}

const STATUS_3DS_ERRORS = {
  [STATUS_3DS.fetch_updates_failed]: STATUS_3DS.fetch_updates_failed,
  [STATUS_3DS.authentication_failed]: STATUS_3DS.authentication_failed,
  [STATUS_3DS.not_authenticated]: STATUS_3DS.not_authenticated,
}

export const is3DSAuthError = (status: STATUS_3DS) => STATUS_3DS_ERRORS[status]

export enum BROWSWER_3DS_STATUS {
  authenticate_initiated = 'authenticate_initiated',
  tds_vs_unsupported = 'tds_vs_unsupported',
  authenticate_browser_failed = 'authenticate_browser_failed',
}

export const allowChallenge = (status: BROWSWER_3DS_STATUS) =>
  status === BROWSWER_3DS_STATUS.authenticate_initiated

let _idHidden
let _idChallenge

export const get3DSiFrameIDHidden = () => _idHidden
export const get3DSiFrameIDChallenge = () => _idChallenge

export const hideChallenge = () => {
  const iframeChallenge = document.getElementById(get3DSiFrameIDChallenge())
  if (iframeChallenge) {
    iframeChallenge.style.display = 'none'
  }
}

type iFrame3DSArgs = {
  challenge_required?: boolean
  value: string
  url: string
}

const create3DSiFrame = ({ challenge_required, value, url }: iFrame3DSArgs) => {
  const iframe = document.createElement('iframe')
  const wrapper3DS = document.getElementById(ID_3DS_WRAPPER)
  const id = generateUUID(
    challenge_required ? PREFIX_3DS_CHALLENGE : PREFIX_3DS
  )

  if (challenge_required) {
    _idChallenge = id
  } else {
    _idHidden = id
  }

  iframe.setAttribute('id', id)

  if (!challenge_required) {
    iframe.style.display = 'none'
  } else {
    iframe.setAttribute('class', 'berkeley-3ds')
  }

  wrapper3DS.appendChild(iframe)

  const iFrameDoc = iframe?.contentDocument || iframe?.contentWindow?.document

  const formName = challenge_required ? 'sendChallenge' : 'sendFingerprint'
  const inputName = challenge_required ? 'creq' : 'threeDSMethodData'

  iFrameDoc.open()
  iFrameDoc.write(`
    <html>
        <body>
            <form name="${formName}" action="${url}" method="post">
                <input name="${inputName}" value="${value}" />
            </form>
        </body>
  `)

  // DO NOT REMOVE THIS IGNORING FILES: ESCAPING IS NECESSARY FOR IFRAME SCRIPT EXECUTE

  // prettier-ignore
  /* eslint-disable no-useless-escape */
  iFrameDoc.write(`\<script>setTimeout(function() {document.createElement('form').submit.call(document.${formName});}, 100);\<\/script>`)
  iFrameDoc.write('</html>')
  iFrameDoc.close()
}

export default create3DSiFrame
