import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const AcademicIcon = ({ size = 1, color = '#398D8D', width, height }) => {
  const defaultWidth = 24
  const defaultHeight = 24
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 29 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6533 7.58666C27.8593 7.70243 28.0308 7.87084 28.1503 8.07466C28.2697 8.27849 28.3329 8.5104 28.3333 8.74666V13.3333C28.3333 13.6869 28.1929 14.0261 27.9428 14.2761C27.6928 14.5262 27.3536 14.6667 27 14.6667C26.6464 14.6667 26.3072 14.5262 26.0572 14.2761C25.8071 14.0261 25.6667 13.6869 25.6667 13.3333V9.51999L23 11.0133V17.3333C23 18.3942 22.5786 19.4116 21.8284 20.1618C21.0783 20.9119 20.0609 21.3333 19 21.3333H8.33333C7.27247 21.3333 6.25505 20.9119 5.50491 20.1618C4.75476 19.4116 4.33333 18.3942 4.33333 17.3333V11.0133L1.01333 9.17333C0.808136 9.05704 0.637458 8.88839 0.518713 8.68461C0.399967 8.48082 0.337402 8.24919 0.337402 8.01333C0.337402 7.77747 0.399967 7.54583 0.518713 7.34204C0.637458 7.13826 0.808136 6.96962 1.01333 6.85333L13.0133 0.186659H13.16C13.2463 0.152098 13.3356 0.125325 13.4267 0.106659H13.92C14.0068 0.125312 14.0916 0.152097 14.1733 0.186659H14.32L26.32 6.85333L27.6533 7.58666ZM24.0437 8.9007L17.9481 12.2911L21.6667 10.2297V10.2318L24.0437 8.9007ZM9.38539 12.2911L1.68253 8.00683L1.67085 8.01332L5.66667 10.2279V10.2297L9.38539 12.2911ZM13.0133 15.8267L7 12.4933V17.3333C7 17.6869 7.14048 18.0261 7.39052 18.2761C7.64057 18.5262 7.97971 18.6667 8.33333 18.6667H19C19.3536 18.6667 19.6928 18.5262 19.9428 18.2761C20.1929 18.0261 20.3333 17.6869 20.3333 17.3333V12.4933L14.32 15.8267C14.2585 15.8647 14.1908 15.8917 14.12 15.9067H14C13.8893 15.9206 13.7773 15.9206 13.6667 15.9067C13.556 15.9206 13.444 15.9206 13.3333 15.9067H13.2133L13.0133 15.8267ZM13.6667 2.85333L4.41333 7.99999L13.6667 13.1467L22.92 7.99999L13.6667 2.85333Z"
        fill={color}
      />
    </svg>
  )
}

export default AcademicIcon
