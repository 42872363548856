import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Button from './Button'
import Info from './Info'
import Paragraph from './typography/Paragraph'

export type OrderedCardStatusNotificationProps = {
  cardOrder: any
}

export type Props = OrderedCardStatusNotificationProps

export enum OrderedPickupCardStatus {
  Pending = 'Pending',
}

const OrderedPickupCardStatusNotification: React.FC<Props> = ({
  cardOrder,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { userProfile, status, pickupLocation } = cardOrder || {}
  const { id: ownerId } = userProfile || {}

  const activateAction = (
    <Button
      nav
      token="ACTIVATE_CARD"
      to={`/cards/activate/${ownerId}`}
      css={styles.link}
    />
  )

  const interpolation = {
    givenName: userProfile?.givenName,
    familyName: userProfile?.familyName,
    location: `${pickupLocation.name}, ${pickupLocation.address}, ${pickupLocation.city}`,
  }

  const renderNotification = () => {
    let notificationComponent
    switch (status) {
      case OrderedPickupCardStatus.Pending:
        notificationComponent = (
          <Info actions={[activateAction]} {...rest}>
            <Paragraph
              token="A_SIDEKICK_CARD_IS_AVAILABLE_FOR_NAME_AT"
              interpolation={interpolation}
              css={styles.content}
            />
            <Paragraph token="ONCE_YOU_HAVE_FUNDS" css={styles.content} />
          </Info>
        )
        break
      default:
        notificationComponent = null
    }
    return notificationComponent
  }

  return renderNotification()
}

const computeStyles: TComputeStyles = ({ LINK, TEXT_PRIMARY }: TTheme) => ({
  root: {
    justifyContent: 'center',
  },
  spinnerContainer: {
    padding: '0 1rem',
  },
  link: {
    background: 'none',
    color: LINK,
    marginLeft: '1rem',
    padding: 0,
    width: 'min-content',
    '&.is-disabled': {
      background: 'none',
    },
  },
  content: {
    color: TEXT_PRIMARY,
    fontSize: 14,
    overflow: 'hidden',
    '&:first-child': {
      margin: 0,
    },
  },
})

export default OrderedPickupCardStatusNotification
