import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import FlowContext from '../../../context/FlowContext'
import Text from '../../../components/typography/Text'

import FormFooter from '../../../components/form/FormFooter'
import Button from '../../../components/Button'

import ManageUserDocumentsScene from '../../settings/documents/documents'
import UploadUserDocumentsScene from '../../settings/documents/upload'

const AddInternationalDocuments: React.FC = () => {
  const { currentData, next } = React.useContext(FlowContext)

  const [showUpload, setShowUpload] = React.useState(false)
  const [showFinishContent, setShowFinishContent] = React.useState(false)

  const userId = currentData?.data?.owner

  const internationalUserName = {
    name: currentData?.data?.userData?.givenName,
  }

  const handleUploadFile = () => {
    setShowUpload(true)
    setShowFinishContent(false)
  }

  const handleFinish = () => {
    setShowFinishContent(true)
    setShowUpload(false)
  }

  return (
    <FlowStepLayout
      title={
        <Text
          token={
            !showFinishContent
              ? 'NEED_VERIFY_IDENTITY'
              : 'UPLOAD_DOCUMENT_SUCCESSFUL'
          }
          interpolation={!showFinishContent ? internationalUserName : undefined}
        />
      }
    >
      {!showFinishContent && <Text token="UPLOAD_STUDENT_LETTER" />}
      {showUpload ? (
        <UploadUserDocumentsScene
          hideTitle
          footer={<Button secondary onClick={next} token="SKIP_FOR_NOW" />}
          id={userId}
          onFinish={handleFinish}
        />
      ) : (
        <ManageUserDocumentsScene
          id={userId}
          onAddDoc={handleUploadFile}
          hideDeleteDocs
        />
      )}
      <FormFooter>
        <Button
          secondary={!showFinishContent}
          onClick={next}
          token={showFinishContent ? 'CONTINUE' : 'SKIP_FOR_NOW'}
        />
      </FormFooter>
    </FlowStepLayout>
  )
}

export default AddInternationalDocuments
