import React from 'react'

import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import StatusCard from './StatusCard'
import Text, { TranslationToken } from '../typography/Text'
import ListItem from '../lists/ListItem'
import ListItemLink from '../lists/ListItemLink'
import clsx from 'clsx'
import KYCSTatusTypes from '../../utils/kyc-status'

type KYCStatusProps = {
  className?: string
  status: KYCSTatusTypes
}

const KYCStatusLink: React.FC<KYCStatusProps> = ({
  status,
  className,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    'is-approved': status === KYCSTatusTypes.Approved,
    'is-failed': status === KYCSTatusTypes.Failed,
  })

  const TOKEN_DICT = {
    [KYCSTatusTypes.Failed]: 'VALIDATION_FAILED',
    [KYCSTatusTypes.Approved]: 'APPROVED',
    [KYCSTatusTypes.Pending]: 'PENDING',
  }

  const TOKEN: TranslationToken = TOKEN_DICT[status]

  if (status === KYCSTatusTypes.None) {
    return (
      <ListItemLink
        to="/settings/kyc"
        label={<Text token="ID_VERIFICATION" />}
      />
    )
  }

  return (
    <ListItem
      to={status === KYCSTatusTypes.Failed ? '/settings/kyc' : undefined}
      css={styles.status}
    >
      <StatusCard
        className={classNames}
        css={styles.root}
        status={<Text token={TOKEN} />}
        label={<Text token="ID_VERIFICATION" />}
        {...rest}
      />
    </ListItem>
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_SECONDARY,
  INVITATION_PENDING,
  INVITATION_ACCEPTED,
  INVITATION_FAILED,
  FIELD_BORDER,
}: TTheme) => ({
  status: {
    borderBottom: `1px solid ${FIELD_BORDER}`,
    flex: '1 auto',
  },
  root: {
    '& > :last-child': {
      color: TEXT_SECONDARY,
      backgroundColor: INVITATION_PENDING,
    },
    '&.is-approved > :last-child': {
      backgroundColor: INVITATION_ACCEPTED,
    },
    '&.is-failed > :last-child': {
      backgroundColor: INVITATION_FAILED,
    },
  },
})

export default KYCStatusLink
