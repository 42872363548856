import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import { TComputeStyles, useTheme } from '@emotion/react'
import { Outlet } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import isString from 'lodash/isString'
import clsx from 'clsx'

import Button from '../Button'
import Steps from '../Steps'
import IconButton from '../IconButton'
import LayoutContext from '../../context/LayoutContext'
import Status from '../statuses/Status'
import AnimatedSpinner from '../AnimatedSpinner'
import SceneZoomInAnimation from '../animations/SceneZoomInAnimation'
import ApplicationContainer from './ApplicationContainer'
import ProcessingStatus from '../ProcessingStatus'
import Text from '../typography/Text'
import { getSafeArea, TSAFE_AREA } from '../../mobile/safearea/safearea-config'

export type CardLayoutProps = {
  checkedSteps?: boolean
} & React.PropsWithChildren

const CardLayout: React.FC<CardLayoutProps> = ({ checkedSteps }) => {
  const { theme, colors, breakpoints } = useTheme()
  const [safeArea, setSafeArea] = React.useState<TSAFE_AREA['insets']>({})
  const [loading, setLoading] = React.useState(false)
  const [title, setTitle] = React.useState()
  const [headerStyle, setHeaderStyle] = React.useState({})
  const [headerExtra, setHeaderExtra] = React.useState()
  const [subtitle, setSubtitle] = React.useState()
  const [back, setBack] = React.useState()
  const [error, setError] = React.useState<ApolloError>()
  const [step, setStep] = React.useState()
  const [steps, setSteps] = React.useState()
  const [cancel, setCancel] = React.useState()
  const [useFooter, setUseFooter] = React.useState(false)
  const [large, setLarge] = React.useState(false)
  const [grayed, setGrayed] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)
  const [processingLabel, setProcessingLabel] = React.useState()
  const navigate = useNavigate()

  const styles = React.useMemo(
    () =>
      computeStyles({
        ...theme,
        ...colors,
        ...breakpoints,
        SAFETOP: headerExtra || steps ? (safeArea?.top || 0) * 0.5 : 0,
        SAFEBOTTOM: safeArea?.bottom || 0,
      }),
    [theme, safeArea, steps, headerExtra]
  )

  const cardClassNames = clsx({
    'is-loading': loading,
    'is-processing': processing,
    'is-error': error,
  })

  const contentCardClassNames = clsx({
    'use-footer': useFooter,
    'is-gray': grayed,
  })

  const headerClassNames = clsx({
    'is-large': large,
  })

  const cardTopClassNames = clsx({
    'is-gray': grayed,
  })

  React.useEffect(() => {
    ;(async () => {
      const { insets } = await getSafeArea()
      setSafeArea(insets)
    })()
  }, [])

  const backIsUrl = isString(back)

  return (
    <LayoutContext.Provider
      value={{
        setTitle,
        setSubtitle,
        setBack,
        setLoading,
        setError,
        setStep,
        setSteps,
        setCancel,
        setUseFooter,
        setLarge,
        setHeaderStyle,
        setHeaderExtra,
        setGrayed,
        setProcessing,
        setProcessingLabel,
      }}
    >
      <ApplicationContainer css={styles.applicationContainer}>
        <SceneZoomInAnimation css={styles.root}>
          <motion.header
            css={{ ...styles.header, ...headerStyle }}
            className={headerClassNames}
          >
            <div css={styles.header.content} className={headerClassNames}>
              {back && backIsUrl && (
                <IconButton icon="arrow" to={back} iconColor={colors.WHITE} />
              )}

              {back && !backIsUrl && (
                <IconButton
                  icon="arrow"
                  iconColor={colors.WHITE}
                  onClick={() => navigate(-1)}
                />
              )}

              <div css={styles.contentColumns} className={headerClassNames}>
                <div css={styles.contentRows}>
                  <div css={styles.titles}>
                    <AnimatePresence>
                      {subtitle && (
                        <motion.div
                          layout
                          initial={{ y: 10, opacity: 0 }}
                          exit={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.2 }}
                          css={styles.subtitle}
                          className={headerClassNames}
                        >
                          {subtitle}
                        </motion.div>
                      )}
                    </AnimatePresence>
                    {title && (
                      <div css={styles.title} className={headerClassNames}>
                        {title}
                      </div>
                    )}
                  </div>

                  {cancel && (
                    <Button
                      to={cancel}
                      label={<Text token="CANCEL" />}
                      css={{
                        background: 'transparent',
                        padding: 0,
                        textTransform: 'uppercase',
                      }}
                    />
                  )}
                </div>
                <AnimatePresence>
                  {steps && (
                    <motion.div css={styles.steps}>
                      <Steps checked={checkedSteps} step={step} steps={steps} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>

            {headerExtra}
            <div css={styles.cardTop} className={cardTopClassNames} />
          </motion.header>
          <div css={styles.content}>
            <div css={styles.content.card} className={contentCardClassNames}>
              <div
                css={styles.content.cardContainer}
                className={cardClassNames}
              >
                <Outlet />
              </div>
              {(loading || error || processing) && (
                <div css={styles.fullscreenContainer}>
                  <AnimatePresence>
                    {loading && <AnimatedSpinner />}
                    {error && <Status error={error} />}
                    {processing && <ProcessingStatus label={processingLabel} />}
                  </AnimatePresence>
                </div>
              )}
            </div>
          </div>
        </SceneZoomInAnimation>
      </ApplicationContainer>
    </LayoutContext.Provider>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  WHITE,
  BLUE_500,
  CARD_GRADIENT,
  BLACK_200,
  SAFETOP,
  SAFEBOTTOM,
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  applicationContainer: {
    backgroundColor: BLUE_500,
  },

  steps: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '1.5rem',
  },

  contentRows: {
    transition: 'all .2s',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },

  contentColumns: {
    display: 'grid',
    gridGap: '1rem',
    width: '100%',

    padding: '0 1.5rem',

    flex: 1,

    '&.is-large': {
      paddingRight: 70,
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '8rem',
    color: WHITE,
    background: CARD_GRADIENT,
    zIndex: 100,
    position: 'sticky',
    top: 0,
    paddingTop: SAFETOP,

    transition: 'height .2s ease-in-out',

    '&.is-large': {
      height: 180,
    },

    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyItems: 'center',
      width: '100%',
      height: 70,

      '&.is-large': {
        flex: 1,
      },
    },
  },

  cardTop: {
    backgroundColor: BACKGROUND,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    height: 20,

    '&.is-gray': {
      background: BLACK_200,
    },
  },

  titles: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  title: {
    fontWeight: '600',
    fontSize: '1.2rem',

    '&.is-large': {
      textAlign: 'center',
      fontSize: 40,
      fontWeight: 400,
    },
  },

  subtitle: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 500,

    '&.is-large': {
      textAlign: 'center',
    },
  },

  content: {
    background: BLUE_500,
    flex: '1 auto',
    display: 'flex',
    flexDirection: 'column',

    card: {
      background: BACKGROUND,

      padding: '0 1rem 1rem 1rem',
      flex: '1',

      position: 'relative',
      display: 'flex',
      flexDirection: 'column',

      '&.use-footer': {
        paddingBottom: '5rem',
      },

      '&.is-gray': {
        background: BLACK_200,
      },
    },

    cardContainer: {
      display: 'grid',
      gridGap: '1rem',
      paddingBottom: SAFEBOTTOM,

      '&.is-loading, &.is-error, &.is-processing': {
        display: 'none',
      },
    },
  },

  fullscreenContainer: {
    flex: 1,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default CardLayout
