import React from 'react'

import Text, { TextProps } from './typography/Text'

type FullnameProps = {
  user: any
} & TextProps

const Fullname: React.FC<FullnameProps> = ({ user, ...rest }) => {
  if (!user) return

  const givenName = user.givenName || user.given_name
  const familyName = user.familyName || user.family_name

  return (
    <Text {...rest}>
      {givenName} {familyName}
    </Text>
  )
}

export default Fullname
