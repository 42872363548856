import React from 'react'

import * as Yup from 'yup'

import RecurlyForm from './RecurlyForm'
import Form from '../Form'
import { useRecurly } from '@recurly/react-recurly'

import CountrySelectField from '../fields/CountrySelectField'
import Field from '../Field'
import FormButton from '../Button'
import FieldCard from '../../cards/FieldCard'
import FormFooter from '../FormFooter'
import Text from '../../typography/Text'

import RECURLY_DATA from 'src/utils/recurly-data'
import { toast } from 'react-hot-toast'

export type PaymentFormProps = {
  user: any
  submit?: Function
  disabled?: boolean
  loading?: boolean
  error?: any
}

const schema = Yup.object().shape({
  givenName: Yup.string().required(),
  familyName: Yup.string().required(),
  principalAddress: Yup.object().shape({
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().required(),
    region: Yup.string().required(),
  }),
  number: Yup.string(),
  month: Yup.number(),
  year: Yup.number(),
  csv: Yup.number(),
})

const PaymentForm: React.FC<PaymentFormProps> = ({
  user,
  submit,
  disabled,
  loading,
  error,
  ...rest
}) => {
  const { givenName, familyName, principalAddress } = user

  const { addressLine1, addressLine2, city, postalCode, region } =
    principalAddress || {}

  const defaultInitialValues = {
    givenName,
    familyName,
    principalAddress: {
      addressLine1: addressLine1 === null ? undefined : addressLine1,
      addressLine2: addressLine2 === null ? undefined : addressLine2,
      city: city === null ? undefined : city,
      country: 'Canada',
      postalCode: postalCode === null ? undefined : postalCode,
      region: region === null ? undefined : region,
    },
  }

  const recurly = useRecurly()

  const formRef = React.useRef(null)
  const submitRef = React.useRef(null)
  const innerFormik = React.useRef(null)

  const handleSubmit = (formData) => {
    const { setFieldError, validateForm } = innerFormik?.current
    validateForm?.()
    recurly.token(formRef.current, (err, token) => {
      if (err) {
        if (err.code !== 'validation') {
          toast.error(err.message)
        }
        err.details.map(({ field, messages }) =>
          setFieldError?.(field, `${field} ${messages[0]}`)
        )
      } else {
        submit(token.id, submitRef?.current?.setLoading, formData)
      }
    })
  }

  return (
    <Form
      innerRef={innerFormik}
      formRef={formRef}
      validationSchema={schema}
      onSubmit={handleSubmit}
      disabled={disabled}
      loading={loading}
      initialValues={defaultInitialValues}
      {...rest}
    >
      <FieldCard label={<Text token="CARD_DETAILS" />}>
        <RecurlyForm form={innerFormik} onSubmit={handleSubmit} />
      </FieldCard>
      <FieldCard label={<Text token="PERSONAL_INFORMATION" />}>
        <Field
          data-recurly={RECURLY_DATA['FIRST_NAME']}
          label={<Text token="FIRST_NAME" />}
          name="givenName"
        />
        <Field
          data-recurly={RECURLY_DATA['LAST_NAME']}
          label={<Text token="LAST_NAME" />}
          name="familyName"
        />
        <Field
          data-recurly={RECURLY_DATA['ADDRESS_LINE_1']}
          label={<Text token="ADDRESS_LINE_1" />}
          name="principalAddress.addressLine1"
        />
        <Field
          data-recurly={RECURLY_DATA['ADDRESS_LINE_2']}
          label={<Text token="ADDRESS_LINE_2" />}
          name="principalAddress.addressLine2"
        />
        <Field
          data-recurly={RECURLY_DATA['CITY']}
          label={<Text token="CITY" />}
          name="principalAddress.city"
        />
        <Field
          data-recurly={RECURLY_DATA['REGION']}
          label={<Text token="PROVINCE_STATE" />}
          name="principalAddress.region"
        />
        <Field
          data-recurly={RECURLY_DATA['POSTAL_CODE']}
          label={<Text token="POSTAL_OR_ZIP_CODE" />}
          name="principalAddress.postalCode"
        />
        <CountrySelectField
          data-recurly={RECURLY_DATA['COUNTRY']}
          label={<Text token="COUNTRY" />}
          name="principalAddress.country"
          disabled
        />
      </FieldCard>

      <FormFooter>
        <FormButton loading={false} ref={submitRef} token="NEXT" />
      </FormFooter>
    </Form>
  )
}

export default PaymentForm
