import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

type ApplicationContainerProps = {
  className?: string
} & React.PropsWithChildren

const ApplicationContainer: React.FC<ApplicationContainerProps> = ({
  children,
  className,
}) => {
  const { colors, theme } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  return (
    <div css={styles.root} className={className}>
      <div css={styles.container}>{children}</div>
    </div>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND }: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND,
    display: 'flex',
    justifyContent: 'center',
  },

  container: {
    maxWidth: 1200,
    width: '100%',
  },
})

export default ApplicationContainer
