import React from 'react'
import { Theme, useTheme } from '@emotion/react'

import CardList from '../cards/CardList'
import ListItemLink from '../lists/ListItemLink'
import ListDetailHeader from '../lists/ListDetailHeader'
import ListItem from '../lists/ListItem'
import Text from '../typography/Text'
import LinkUserStatus from './LinkUserStatus'
import KYCStatus from './KYCStatusLink'

import { IconType } from '../icons'
import { ColorType } from '../../utils/themes'
import KYCSTatusType from '../../utils/kyc-status'

type TeamMemberType = {
  user: any
  icon?: IconType
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  baseUrl?: string
  color?: ColorType
  className?: string
  showInvitationStatus?: boolean
  sidekick?: boolean
}

const TeamMember: React.FC<TeamMemberType> = ({
  user,
  subtitle,
  baseUrl,
  className,
  showInvitationStatus = true,
  sidekick,
}) => {
  const base = baseUrl || `/settings/team/${user.id}`

  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const statusKYC = user?.kycValidationStatus || KYCSTatusType.None

  const innerEmail = user?.email?.includes?.('herofinancials.app')

  return (
    <CardList padding="0" className={className}>
      <ListDetailHeader user={user} subtitle={subtitle} />
      {showInvitationStatus && (
        <ListItem to="" css={styles.status}>
          <LinkUserStatus
            label={<Text token="INVITATION_STATUS" />}
            accepted={user?.hasAcceptedInvitation}
          />
        </ListItem>
      )}
      {!sidekick && <KYCStatus status={statusKYC} />}

      <ListItemLink
        label={<Text token="PERSONAL_INFORMATION" />}
        to={`${base}/personal-information`}
      />

      {!innerEmail && (
        <ListItemLink
          label={<Text token="LOGIN_DETAILS" />}
          to={`${base}/login-details`}
        />
      )}

      <ListItemLink
        label={<Text token="MANAGE_USER_DOCUMENTS" />}
        to={`${base}/documents`}
        feature="setting.documents"
      />
      <ListItemLink
        disabled
        label={<Text token="PROFILE_PHOTO" />}
        to={`${base}/profile-photo`}
      />
      <ListItemLink
        disabled
        label={<Text token="CARD_SETTINGS" />}
        to={`${base}/card-settings`}
      />
    </CardList>
  )
}

const computeStyles = ({ FIELD_BORDER }: Theme['theme']) => ({
  status: {
    borderBottom: `1px solid ${FIELD_BORDER}`,
    flex: '1 auto',
  },
})

export default TeamMember
