import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Button from '../../components/Button'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import ThanksOrderForm from '../../components/form/forms/ThanksOrderForm'
import Info from '../../components/Info'
import Paragraph from '../../components/typography/Paragraph'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'
import { getUserProfile, openCardOrders, teamMembers } from '../../queries'
import { CONTACT_MAILTO } from '../settings/contact-us/contactUs'

const ThanksStep = ({ ...rest }) => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { currentData, setFlowData } = React.useContext(FlowContext)
  const { data: teamMembersData } = useQuery(teamMembers)
  const { data: user } = useQuery(getUserProfile)
  const { refetch } = useQuery(openCardOrders)

  const sk =
    teamMembersData.teamMembers.find(
      (acc) => acc.userProfile?.id === currentData.data.forUser
    )?.userProfile || user?.userProfile

  const fullName = `${sk?.givenName} ${sk?.familyName}`

  const { shippingAddress = {} } = currentData.data
  const { country, addressLine1, addressLine2, city, postalCode, region } =
    shippingAddress

  const handleSubmit = async () => {
    await refetch()
    navigate('/cards')
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })
  return (
    <FlowStepLayout>
      <Text css={styles.legend} token="YOUR_SIDEKICK_CARD_IN_ON_THE_WAY_TO" />
      <Paragraph>
        <Text css={styles.legend} as="div">
          {fullName}
        </Text>
        <Text css={styles.legend} as="div">
          {addressLine1}
        </Text>
        {addressLine2 && (
          <Text css={styles.legend} as="div">
            {addressLine2}
          </Text>
        )}
        <Text css={styles.legend} as="div">
          {city}
        </Text>
        <Text
          css={styles.legend}
          as="div"
        >{`${region}, ${country}, ${postalCode}`}</Text>
      </Paragraph>
      <Info
        token="IF_YOU_SEE_ANYTHING_WRONG_WITH_THE_ADDRESS"
        actions={[
          <Button
            button
            css={styles.emailUs}
            token="EMAIL_US"
            onClick={(e) => {
              window.location.href = CONTACT_MAILTO
              e.preventDefault()
            }}
          />,
        ]}
      />
      <ThanksOrderForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        {...rest}
      />
    </FlowStepLayout>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY, LINK }: TTheme) => ({
  root: {},
  legend: {
    fontSize: '14px',
    color: TEXT_PRIMARY,
  },
  emailUs: {
    background: 'none',
    color: LINK,
    width: 'min-content',
    padding: 0,
  },
})

export default ThanksStep
