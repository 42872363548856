import React from 'react'
import * as Yup from 'yup'

import Form from '../Form'
import FromToFieldCategory from '../fields/FromToFieldCategory'
import Detail from '../../typography/Detail'
import Text from '../../typography/Text'
import FormFooter from '../FormFooter'
import Button from '../Button'
import Info from '../../Info'

import { TComputeStyles, useTheme } from '@emotion/react'
import { getAmount } from '../../../utils/functions'
import CurrencyField, {
  ALLOCATE_VALIDATOR_REQUIRED,
} from '../fields/CurrencyField'

const CommonSchema = {
  fromCategory: Yup.object().required(),
  toCategory: Yup.object().required(),
}
// Types
type ReallocateFormProps = {
  categories: Array<any>
  loading?: boolean
  onSubmit: Function
}

const ReallocateForm: React.FC<ReallocateFormProps> = ({
  categories,
  loading,
  onSubmit = () => {},
  ...rest
}) => {
  const { theme } = useTheme()
  const formRef = React.useRef(null)
  const fromCategory = categories[0]
  const toCategory = categories[1]

  const [maxAmount, setMaxAmount] = React.useState(
    getAmount(fromCategory?.fundsAvailable)
  )

  const validator = (fundsAvailable) =>
    Yup.object().shape({
      ...CommonSchema,
      amount: ALLOCATE_VALIDATOR_REQUIRED(getAmount(fundsAvailable)),
    })

  const [schema, setSchema] = React.useState(
    validator(fromCategory?.fundsAvailable)
  )
  const initialValues = { toCategory, fromCategory, amount: 0 }

  const handleSubmit = (formData) => {
    const values = formData || {}
    onSubmit(values)
  }

  const handleChange = (field, value) => {
    if (field === 'fromCategory') {
      setMaxAmount(getAmount(value?.fundsAvailable))
      setSchema(validator(value?.fundsAvailable))
    }
  }

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Form
      loading={loading}
      innerRef={formRef}
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      {...rest}
    >
      {(form) => (
        <>
          <FromToFieldCategory
            onChange={handleChange}
            categories={categories}
            disabled={loading}
            to={form?.values?.toCategory}
            from={form?.values?.fromCategory}
          />
          <Detail css={styles.text} token="AMOUNT_TO_MOVE" />
          <CurrencyField
            prefix="$ "
            disabled={!maxAmount}
            css={styles.field}
            name="amount"
            label={<Text token="AMOUNT" />}
          />
          {!maxAmount && <Info token="NOT_ENOUGH_FUNDS" />}
          <FormFooter>
            <Button
              loading={loading}
              disabled={!maxAmount || !form?.values?.toCategory}
              token="CONFIRM"
            />
          </FormFooter>
        </>
      )}
    </Form>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }) => ({
  text: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  price: {
    fontSize: '32px',
    marginBottom: 10,
  },
  priceSmall: {
    fontSize: '18px',
  },
  field: {
    background: BACKGROUND_ALT,
  },
})

export default ReallocateForm
