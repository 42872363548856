import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import * as Yup from 'yup'
import find from 'lodash/find'

import FormButton from '../Button'
import Button from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'
import RadioField from '../fields/RadioField'
import Text, { TranslationToken } from '../../typography/Text'
import Detail from '../../typography/Detail'
import FormHeader from '../../typography/FormHeader'
import FundingMethodOptionField from '../fields/FundingMethodOptionField'

import { cleanNullProps } from '../../../utils/functions'
import FlowContext from '../../../context/FlowContext'
import CurrencyField from '../fields/CurrencyField'
import InfoAlert from '../../InfoAlert'

export type FundSelectFundingMethodFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
  fundingSources?: Array<{ [key: string]: any }>
}

const schema = Yup.object().shape({
  amount: Yup.number().moreThan(0).required('THIS_IS_A_REQUIRED_FIELD'),
  selectedFundingMethod: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
})

const FundSelectFundingMethodForm: React.FC<
  FundSelectFundingMethodFormProps
> = ({ submit, loading, fundingSources, ...rest }) => {
  const { currentData, setFlowData, prev } = React.useContext(FlowContext)
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const hasFundingSources = fundingSources?.length > 0

  const defaultInitialValues = {
    ...currentData?.data,
    selectedFundingMethod: hasFundingSources ? fundingSources?.[0]?.id : 'new',
  }

  const createFundingMethod = currentData?.data?.selectedFundingMethod === 'new'
  const handleSubmit = (formData) => {
    const fundingSourceModel = find(
      fundingSources,
      (fundingSource) => fundingSource.id === formData.selectedFundingMethod
    )
    setFlowData({ fundingSourceModel })

    submit(cleanNullProps(formData))
  }
  const submitButtonToken: TranslationToken =
    createFundingMethod || !hasFundingSources ? 'NEXT' : 'MAKE_PAYMENT'

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <FormHeader token="HOW_MUCH_WOULD_YOU_LIKE_TO_ADD" />

      <CurrencyField
        css={styles.field}
        label={<Text token="AMOUNT" uppercase />}
        prefix="$ "
        name="amount"
      />

      <FormHeader token="FUNDING_METHOD" />

      <Detail token="WHICH_FUNDING_METHOD_WOULD_YOU_LIKE_TO_USE" />

      {fundingSources &&
        fundingSources.map((fundingSource) => (
          <FundingMethodOptionField
            key={`fundingSource_${fundingSource?.id}`}
            name="selectedFundingMethod"
            funding={fundingSource}
            value={fundingSource?.id}
            css={styles.field}
          />
        ))}

      <RadioField
        css={styles.field}
        name="selectedFundingMethod"
        label={<Text token="NEW_FUNDING_METHOD" />}
        value="new"
        icon="plus"
        disabled={hasFundingSources}
        showBorder
      />

      {hasFundingSources && (
        <InfoAlert token="ADD_FUNDING_METHOD_CONTACT_SUPPORT" />
      )}

      <FormFooter css={styles.footer}>
        <Button button onClick={() => prev()} token="PREVIOUS_STEP" />
        <FormButton token={submitButtonToken} />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default FundSelectFundingMethodForm
