import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import create3DSiFrame, {
  STATUS_3DS,
  hideChallenge,
  allowChallenge,
  is3DSAuthError,
} from '../../services/berkeley'
import FormFooter from '../../components/form/FormFooter'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import { IconType } from '../../components/icons'
import Detail from '../../components/typography/Detail'
import use3DS from '../../hooks/use3DSAuth'
import FlowContext from '../../context/FlowContext'
import {
  calculateAmountInCents,
  DEFAULT_CURRENCY,
  DEFAULT_3DSIFRAME_TIMEOUT,
} from '../../utils/funds'

type contentProps = {
  icon: IconType | string
  iconSize: number
  iconColor: string
  description?: string
}

const generateContent = (description?: string) => {
  const content: contentProps = {
    icon: 'error',
    iconSize: 4.167,
    iconColor: 'ERROR_ALT',
  }
  if (content && description) {
    content.description = description
  }
  return content
}

let isChallenging = false

const FundAccount3DSAuth: React.FC = () => {
  const { setFlowData, currentData, next, prev } = React.useContext(FlowContext)

  const [loading, setLoading] = React.useState(true)
  const [content, setContent] = React.useState<contentProps>()

  const fundingSourceId = currentData?.data?.fundingSourceModel?.id
  const amountInCents = calculateAmountInCents(currentData?.data?.amount)

  const [do3DSAuth, get3DSStatus] = use3DS()

  const handleError = (error) => {
    isChallenging = false
    setLoading(false)
    setContent(generateContent(error))
  }

  const init3DSAuth = async () => {
    let data
    try {
      const { data: auth3DSAuth } = await do3DSAuth({
        variables: {
          fundingSourceId,
          amountInCents,
          currency: DEFAULT_CURRENCY,
        },
      })
      data = auth3DSAuth
    } catch (err) {
      return handleError(err.message)
    }

    const berkeleyData = data?.createBerkeley3dsAuthentication
    const browserStatus = berkeleyData.status

    if (browserStatus === STATUS_3DS.authenticated) {
      const { id } = berkeleyData
      setFlowData({
        berkeley3dsAuthenticationId: id,
      })
      return next()
    }

    if (!allowChallenge(browserStatus)) return handleError(browserStatus)

    const value = berkeleyData.three_ds_method_data
    const url = berkeleyData.method_url

    create3DSiFrame({
      value,
      url,
    })

    const poll = setInterval(async () => {
      let statusData
      try {
        const { data: status3DSStatus } = await get3DSStatus({
          variables: { id: berkeleyData.id },
        })
        statusData = status3DSStatus
      } catch (error) {
        return handleError(error.message)
      }

      const response3DS = statusData?.berkeley3dsAuthentication

      const { status } = response3DS

      if (is3DSAuthError(status)) {
        clearInterval(poll)
        return handleError(status)
      }

      if (status === STATUS_3DS.challenge_required) {
        const url = response3DS.acs_url
        const value = response3DS.creq

        if (!isChallenging) {
          create3DSiFrame({
            url,
            value,
            challenge_required: true,
          })
          isChallenging = true
          setLoading(false)
        }
      }

      if (
        status === STATUS_3DS.authenticated ||
        status === STATUS_3DS.authentication_attempt_acknowledged
      ) {
        isChallenging = false
        setContent(undefined)
        hideChallenge()
        setFlowData({
          berkeley3dsAuthenticationId: response3DS?.id,
        })
        next()
        clearInterval(poll)
      }
    }, DEFAULT_3DSIFRAME_TIMEOUT)
  }

  const handleBack = () => {
    hideChallenge()
    prev()
  }

  React.useEffect(() => {
    init3DSAuth()
    return () => {
      hideChallenge()
    }
  }, [])

  return (
    <FlowStepLayout loading={loading}>
      {content && (
        <>
          <Icon
            css={styles.icon}
            icon={content.icon}
            color={content.iconColor}
            size={content.iconSize}
          />
          <Detail
            css={styles.copy}
            capitalize={false}
            token={content.description}
          />
        </>
      )}
      <FormFooter>
        <Button
          button
          token="PREVIOUS_STEP"
          onClick={handleBack}
          disabled={loading}
        />
      </FormFooter>
    </FlowStepLayout>
  )
}

const styles = {
  copy: {
    textAlign: 'center',
    width: '100%',
  },
  icon: {
    margin: '40px 0 20px 0',
  },
}

export default FundAccount3DSAuth
