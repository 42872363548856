import { useMutation, useLazyQuery } from '@apollo/client'

import {
  createBerkeley3dsAuthentication,
  getBerkeley3dsAuthentication,
} from '../queries'

export const use3DS = () => {
  const [do3DSAuth, { data: auth3DSData, loading: auth3DSLoading }] =
    useMutation(createBerkeley3dsAuthentication)
  const [
    get3DSStatus,
    { data: auth3DSStatusData, loading: auth3DSStatusLoading },
  ] = useLazyQuery(getBerkeley3dsAuthentication, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  return [
    // actions
    do3DSAuth,
    get3DSStatus,
    // Data
    {
      auth3DSData,
      auth3DSLoading,
      auth3DSStatusData,
      auth3DSStatusLoading,
    },
  ]
}

export default use3DS
