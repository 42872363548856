import React from 'react'
import { useNavigate } from 'react-router-dom'
import FlowStepLayout from '../../components/flows/FlowStepLayout'

import FundAmountForm from '../../components/form/forms/FundAmountForm'

import FlowContext from '../../context/FlowContext'
import useCurrentUser from '../../hooks/useCurrentUser'
import KYCSTatusType from '../../utils/kyc-status'

const FundAccountInputAmountStep = ({ ...rest }) => {
  const { data: user } = useCurrentUser()
  const navigate = useNavigate()
  const { currentData, setFlowData, next } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    if (user?.userProfile?.kycValidationStatus === KYCSTatusType.Approved) next()
    else navigate('/settings/kyc')
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  const defaultInitialValues = {
    ...currentData?.data,
  }

  return (
    <FlowStepLayout>
      <FundAmountForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={defaultInitialValues}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default FundAccountInputAmountStep
