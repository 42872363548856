import React from 'react'

import Header from '../typography/Header'

import FlowContext from '../../context/FlowContext'
import LayoutContext from '../../context/LayoutContext'

type FlowStepLayoutProps = {
  loading?: boolean
  title?: string | JSX.Element
} & React.PropsWithChildren

const FlowStepLayout: React.FC<FlowStepLayoutProps> = ({
  children,
  loading,
  title,
}) => {
  const { currentStep } = React.useContext(FlowContext)
  const { setLoading } = React.useContext(LayoutContext)

  React.useEffect(() => {
    setLoading(loading)
  }, [loading])

  const headingTitle = title || currentStep?.title

  return (
    <>
      {headingTitle && <Header css={styles.headline}>{headingTitle}</Header>}
      {children}
    </>
  )
}

const styles = {
  headline: {
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 24,
    margin: '40px 0 0',
  },
}

export default FlowStepLayout
