import React from 'react'

import SelectField from './SelectField'

import countries from '../../../utils/countries'
import { FieldProps } from '../Field'

type CountrySelectFieldProps = {
  abbreviation: boolean
} & FieldProps

const CountrySelectField: React.FC<CountrySelectFieldProps> = ({
  abbreviation = false,
  ...rest
}) => {
  return (
    <SelectField {...rest}>
      {countries.map((country) => (
        <option
          key={`country_${country.code}_${country.abbreviation}`}
          value={abbreviation ? country.abbreviation : country.country}
        >
          {country.country}
        </option>
      ))}
    </SelectField>
  )
}

export default CountrySelectField
