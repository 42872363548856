import { useMutation, useQuery } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import OrderCardInternationalPickupSummaryForm from '../../components/form/forms/OrderCardInternationalPickupSummaryForm'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'
import {
  findPickupLocations,
  getUserProfile,
  pickupCard as pickupCardMutation,
  teamMembers,
} from '../../queries'

const SIDEKICK_CARD_ACCOUNT = 'SideKickCardAccount'

const InternationalPickupSummaryStep = ({ ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { currentData, next, setFlowData } = React.useContext(FlowContext)
  const { data: user } = useQuery(getUserProfile)
  const { loading: teamMembersLoading, data: teamMembersData } =
    useQuery(teamMembers)

  const [pickupCard, { loading }] = useMutation(pickupCardMutation)
  const { data: pickupLocationsData, loading: pickupLocationsLoading } =
    useQuery(findPickupLocations)

  const { pickupLocations = [] } = pickupLocationsData || {}

  const pickupLocation = pickupLocations.find(
    (loc) => loc.code === currentData?.data?.pickupLocation
  )

  const sk =
    teamMembersData.teamMembers.find(
      (acc) => acc.userProfile?.id === currentData.data.forUser
    ) || user

  const handleSubmit = async (formData) => {
    const { forUser, pickupLocation, arrivalDate } = currentData.data

    const variables = {
      forUser,
      pickupLocation,
      arrivalDate,
      bankAccountType: SIDEKICK_CARD_ACCOUNT,
    }
    const newCard = await pickupCard({ variables })
    await setFlowData(formData)
    if (newCard) next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })
  return (
    <FlowStepLayout>
      <Text
        css={styles.legend}
        token="PLEASE_CHECK_BEFORE_CONFIRMING_YOUR_ORDER"
      />
      <OrderCardInternationalPickupSummaryForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data, sk: sk?.userProfile }}
        pickupLocation={pickupLocation}
        loading={loading || teamMembersLoading || pickupLocationsLoading}
        {...rest}
      />
    </FlowStepLayout>
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  root: {},
  legend: {
    fontSize: '14px',
    color: TEXT_PRIMARY,
  },
  rushHeader: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '.5px',
    lineHeight: '32px',
    margin: 0,
    marginBottom: '16px',
  },
  rushDescription: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    margin: 0,
    wordBreak: 'break-word',
  },
  rushShippingLabel: {
    alignSelf: 'end',
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  shippingAmount: {
    alignSelf: 'end',
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '20px',
    letterSpacing: '-.25px',
    wordBreak: 'break-word',
  },
  totalLabel: {
    alignSelf: 'end',
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  totalAmount: {
    alignSelf: 'end',
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '48px',
    fontWeight: 300,
    letterSpacing: '-.25px',
    wordBreak: 'break-word',
  },
})

export default InternationalPickupSummaryStep
