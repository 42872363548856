import ActivityIcon from './Activity'
import BrandIcon from './Brand'
import BrandGradientIcon from './BrandGradient'
import CogIcon from './Cog'
import ErrorIcon from './Error'
import ArrowIcon from './Arrow'
import ChevronIcon from './Chevron'
import InfoIcon from './Info'
import MoreInfoIcon from './MoreInfo'
import TimesIcon from './Times'
import PlusIcon from './Plus'
import EyeIcon from './Eye'
import TransactionIcon from './Transaction'
import ExchangeIcon from './Exchange'
import RepeatIcon from './Repeat'
import TrashCanIcon from './TrashCan'
import CalculatorIcon from './Calculator'
import KeyholeCircleIcon from './KeyholeCircle'
import MaskIcon from './Mask'
import BanIcon from './Ban'
import BankNoteIcon from './BankNote'
import VisaMonoIcon from './VisaMono'
import MastercardMonoIcon from './MastercardMono'
import MoreVerticalIcon from './MoreVertical'
import CheckIcon from './Check'
import CardIcon from './Card'
import AlertIcon from './Alert'
import UserIcon from './User'
import IdIcon from './Id'
import CheckCircleIcon from './CheckCircle'
import AlertCircleIcon from './AlertCircle'
import Truck from './Truck'
import MapPin from './MapPin'
import UploadIcon from './Upload'
import BankIcon from './Bank'
import AcademicIcon from './Academic'

// Category icons
import GeneralIcon from './General'
import HomeIcon from './Home'
import FoodIcon from './Food'
import EntretainmentIcon from './Entertainment'
import TravelIcon from './Travel'
import ShoppingIcon from './Shopping'
import LockIcon from './Lock'
import MoneyWithdrawalIcon from './MoneyWithdrawal'
import GlobeIcon from './Globe'
import LeafIcon from './Leaf'
import NewMember from './NewMember'
import SwitchIcon from './Switch'
import InvoiceIcon from './Invoice'
import EditIcon from './Edit'

// Credit Cards
import GenericCardIcon from './cards/Generic'
import AmexIcon from './cards/Amex'
import VisaIcon from './cards/Visa'
import MastercardIcon from './cards/Mastercard'
import DinerCard from './cards/Diner'

export { default as GenericCardIcon } from './cards/Generic'
export { default as AmexIcon } from './cards/Amex'
export { default as VisaIcon } from './cards/Visa'
export { default as MastercardIcon } from './cards/Mastercard'
export { default as DinerCard } from './cards/Diner'

export { default as CheckIcon } from './Check'
export { default as ErrorIcon } from './Error'
export { default as BrandIcon } from './Brand'
export { default as BrandGradientIcon } from './BrandGradient'
export { default as CogIcon } from './Cog'
export { default as ActivityIcon } from './Activity'
export { default as ArrowIcon } from './Arrow'
export { default as ChevronIcon } from './Chevron'
export { default as InfoIcon } from './Info'
export { default as MoreInfoIcon } from './MoreInfo'
export { default as TimesIcon } from './Times'
export { default as PlusIcon } from './Plus'
export { default as EyeIcon } from './Eye'
export { default as TransactionIcon } from './Transaction'
export { default as ExchangeIcon } from './Exchange'
export { default as RepeatIcon } from './Repeat'
export { default as TrashCanIcon } from './TrashCan'
export { default as CalculatorIcon } from './Calculator'
export { default as KeyholeCircleIcon } from './KeyholeCircle'
export { default as MaskIcon } from './Mask'
export { default as BanIcon } from './Ban'
export { default as BankNoteIcon } from './BankNote'
export { default as VisaMonoIcon } from './VisaMono'
export { default as MastercardMonoIcon } from './MastercardMono'
export { default as SwitchIcon } from './Switch'
export { default as InvoiceIcon } from './Invoice'
export { default as EditIcon } from './Edit'
export { default as CardIcon } from './Card'
export { default as AlertIcon } from './Alert'
export { default as UserIcon } from './User'
export { default as IdIcon } from './Id'
export { default as CheckCircleIcon } from './CheckCircle'
export { default as AlertCircleIcon } from './AlertCircle'
export { default as MoreVerticalIcon } from './MoreVertical'

export { default as GeneralIcon } from './General'
export { default as HomeIcon } from './Home'
export { default as FoodIcon } from './Food'
export { default as EntretainmentIcon } from './Entertainment'
export { default as TravelIcon } from './Travel'
export { default as ShoppingIcon } from './Shopping'
export { default as LockIcon } from './Lock'
export { default as MoneyWithdrawalIcon } from './MoneyWithdrawal'
export { default as GlobeIcon } from './Globe'
export { default as LeafIcon } from './Leaf'
export { default as NewMember } from './NewMember'
export { default as Truck } from './Truck'
export { default as MapPin } from './MapPin'

const ICONS = {
  check: CheckIcon,
  error: ErrorIcon,
  brand: BrandIcon,
  brand_gradient: BrandGradientIcon,
  cog: CogIcon,
  activity: ActivityIcon,
  arrow: ArrowIcon,
  chevron: ChevronIcon,
  info: InfoIcon,
  moreInfo: MoreInfoIcon,
  times: TimesIcon,
  plus: PlusIcon,
  eye: EyeIcon,
  transaction: TransactionIcon,
  exchange: ExchangeIcon,
  repeat: RepeatIcon,
  trashCan: TrashCanIcon,
  calculator: CalculatorIcon,
  keyholeCircle: KeyholeCircleIcon,
  mask: MaskIcon,
  ban: BanIcon,
  bankNote: BankNoteIcon,
  visaMono: VisaMonoIcon,
  mastercardMono: MastercardMonoIcon,
  switch: SwitchIcon,
  invoice: InvoiceIcon,
  edit: EditIcon,
  moreVertical: MoreVerticalIcon,
  // Category List
  general: GeneralIcon,
  home: HomeIcon,
  food: FoodIcon,
  entertainment: EntretainmentIcon,
  travel: TravelIcon,
  shopping: ShoppingIcon,
  lock: LockIcon,
  moneyWithdrawal: MoneyWithdrawalIcon,

  globe: GlobeIcon,
  leaf: LeafIcon,
  newMember: NewMember,
  truck: Truck,
  mapPin: MapPin,

  genericCard: GenericCardIcon,
  amex: AmexIcon,
  visa: VisaIcon,
  mastercard: MastercardIcon,
  diner: DinerCard,

  card: CardIcon,
  alert: AlertIcon,
  user: UserIcon,
  id: IdIcon,
  checkCircle: CheckCircleIcon,
  alertCircle: AlertCircleIcon,
  upload: UploadIcon,
  bank: BankIcon,
  academic: AcademicIcon,
}

export type IconType = keyof typeof ICONS

export const iconsList = Object.keys(ICONS)

export default ICONS
