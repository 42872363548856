import React from 'react'
import isEqual from 'lodash/isEqual'

import AvatarInitials from '../../AvatarInitials'
import Flex from '../../Flex'
import OptionCircle from './OptionCircle'
import FieldText from '../../typography/FieldText'
import ComponentCard from '../../../components/cards/ComponentCard'
import { SelectDrawerFieldContext } from '../../form/fields/SelectDrawerField'

import { getFullName } from '../../../utils/functions'

export const MemberItem = ({ user, withInitials = true, ...rest }) => (
  <Flex centerY gap="1rem">
    {withInitials && (
      <AvatarInitials color="YELLOW" size={50} user={user.userProfile} />
    )}
    <FieldText {...rest}>
      {user ? getFullName(user.userProfile) : '-'}
    </FieldText>
  </Flex>
)

const SelectDrawerUserOptionItem = ({
  user,
  selected,
  onClick,
  className,
  ...rest
}) => {
  return (
    <ComponentCard selected={selected} onClick={onClick}>
      <Flex centerY spread>
        <MemberItem user={user} {...rest} />
        <OptionCircle selected={selected} />
      </Flex>
    </ComponentCard>
  )
}

const SelectDrawerMemberOption = ({ option, ...rest }) => (
  <SelectDrawerFieldContext.Consumer>
    {({ selected, select }) => (
      <SelectDrawerUserOptionItem
        user={option}
        selected={isEqual(selected, option)}
        onClick={() => select(option)}
        {...rest}
      />
    )}
  </SelectDrawerFieldContext.Consumer>
)

export default SelectDrawerMemberOption
