import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import DateOfArrivalForm from '../../components/form/forms/DateOfArrivalForm'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'

const DateOfArrivalStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout>
      <Text token="IF_THE_STUDENT_IS_ALREADY_IN_CANADA" />
      <DateOfArrivalForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default DateOfArrivalStep
