import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ID_3DS_WRAPPER } from './services/berkeley'
import './config/amplify'
import apiClient from './config/api'
import translator from './config/translations'
import store from './store'
import Theme from './components/Theme'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './config/http'
import './global.css'
import isMobile from './mobile/utils/isMobile'

const isMobileApp = isMobile()

const isProd = process.env.REACT_APP_ENABLE_ANALYTICS === 'true'

const showAnalytics = !isMobileApp && isProd

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <ApolloProvider client={apiClient}>
      <I18nextProvider i18n={translator}>
        <BrowserRouter>
          <Helmet>
            {showAnalytics &&
              '<!-- Global site tag (gtag.js) - Google Ads: 10927366690 -->'}
            {showAnalytics && (
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-10927366690"
              ></script>
            )}
            {showAnalytics && (
              <script>{`window.dataLayer = window.dataLayer || []
              function gtag() {
                dataLayer.push(arguments)
              }
              gtag('js', new Date())

              gtag('config', 'AW-10927366690')`}</script>
            )}

            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
            <title>Hero Financials</title>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="true"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <script src="https://js.recurly.com/v4/recurly.js"></script>
            <script
              type="text/javascript"
              src="https://js.trulioo.com/latest/main.js"
            ></script>

            {showAnalytics && '<!-- Meta Pixel Code -->'}
            {showAnalytics && (
              <script>
                {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '625954438645693');
            fbq('track', 'PageView');`}
              </script>
            )}
            {showAnalytics && (
              <noscript>
                {`<img
                alt=""
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=625954438645693&ev=PageView&noscript=1"
              />`}
              </noscript>
            )}
            {showAnalytics && '<!-- End Meta Pixel Code -->'}
          </Helmet>

          <div id={ID_3DS_WRAPPER} />

          <Theme>
            <App />
          </Theme>
        </BrowserRouter>
      </I18nextProvider>
    </ApolloProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
