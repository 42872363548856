import React from 'react'
import { useTheme } from '@emotion/react'

import Text, { TextProps } from './Text'

const FieldText: React.FC<TextProps> = ({ ...rest }) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles({ ...theme }), [theme])

  return <Text css={styles.root} {...rest} />
}

const computeStyles = ({ TEXT_PRIMARY_DARK }) => ({
  root: {
    color: TEXT_PRIMARY_DARK,
    fontSize: 17,
  },
})

export default FieldText
