export const BLUE_50 = '#E6F1FA'
export const BLUE_100 = '#D8EAFF'
export const BLUE_200 = '#AED4FE'
export const BLUE_300 = '#77AEEC'
export const BLUE_400 = '#507FB4'
export const BLUE_500 = '#466990'
export const BLUE_600 = '#335377'
export const BLUE_700 = '#2D3F54'

export const TURQUOISE_600 = '#398D8D'
export const TURQUOISE_500 = '#47A2A2'
export const TURQUOISE_450 = '#5FB797'
export const TURQUOISE_400 = '#49DCB1'
export const TURQUOISE_300 = '#77FCD5'
export const TURQUOISE_200 = '#CAFFF0'

export const RED_FROLI = '#EF767A'
export const RED_FROLI_600 = '#E4686C'

export const RED_700 = '#D92C00'
export const RED_600 = '#F23100'
export const RED_500 = '#FF3400'
export const RED_400 = '#FF523A'
export const RED_200 = '#FFD8E6'
export const RED_100 = '#FCEFEC'

export const YELLOW = '#F0F600'
export const YELLOW_400 = '#FAFF40'

export const ORANGE = '#F26901'
export const ORANGE_700 = '#E67E00'
export const ORANGE_600 = '#F28500'
export const ORANGE_500 = '#FF8C00'
export const ORANGE_400 = '#FF8C00'

export const GREEN_700 = '#0EA800'
export const GREEN_600 = '#0FB200'
export const GREEN_500 = '#10BB00'
export const GREEN_400 = '#4CCC40'

export const CATEGORY_TURQUOISE = '#77E5BD'
export const CATEGORY_CYAN = '#77E5DC'
export const CATEGORY_ACQUAMARINE = '#77D5E0'
export const CATEGORY_SKY = '#77C6E5'
export const CATEGORY_BLUE = '#77AEEC'
export const CATEGORY_PURPLE = '#96ABF6'
export const CATEGORY_YELLOW = YELLOW
export const CATEGORY_LIGHT_TURQUOISE = TURQUOISE_300

export const BLACK = '#000000'
export const BLACK_900 = '#464E58'
export const BLACK_800 = '#717983'
export const BLACK_700 = '#8A929C'
export const BLACK_600 = '#A0A8B2'
export const BLACK_500 = '#BCC4CE'
export const BLACK_400 = '#D5DAE1'
export const BLACK_300 = '#E0E4E9'
export const BLACK_200 = '#EBEEF1'
export const BLACK_100 = '#F3F5F7'
export const BACKGROUND = '#F5F7FA'
export const WHITE = '#FFFFFF'
