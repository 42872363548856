import React from 'react'
import clsx from 'clsx'
import { useTheme, TComputeStyles } from '@emotion/react'

import Flex from '../../Flex'

const OptionCircle = ({ selected, className, ...rest }) => {
  const { theme } = useTheme()
  const styles = computeStyles(theme)

  const classNames = clsx({
    [className]: className,
    'is-selected': selected,
  })

  return <Flex css={styles.root} className={classNames} {...rest} />
}

const computeStyles: TComputeStyles = ({
  FIELD_RADIO_SELECTED,
  FIELD_RADIO_UNSELECTED,
}) => ({
  root: {
    boxSizing: 'border-box',
    border: `1px solid ${FIELD_RADIO_UNSELECTED}`,
    height: 20,
    width: 20,
    borderRadius: '100%',

    '&.is-selected': {
      border: `6px solid ${FIELD_RADIO_SELECTED}`,
    },
  },
})

export default OptionCircle
