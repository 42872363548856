import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
import { DateTime } from 'luxon'
import isNull from 'lodash/isNull'
import truncate from 'lodash/truncate'
import { API } from 'aws-amplify'
import { TranslationToken } from '../components/typography/Text'

export const isCustomerSupportDeposit = (type: string): boolean =>
  type === 'CustomerSupportDeposit'

export const cleanNullProps = (source) =>
  omitBy(omitBy(source, isUndefined), isNull)

export const getFullName = (userProfile) =>
  `${userProfile?.givenName} ${userProfile?.familyName}`

export const normalizeTransactionModel = (transaction) => {
  switch (transaction.__typename) {
    case 'HoldingAccountTransactionEdge':
      return {
        ...transaction,
        node: {
          ...transaction.node,
          billingAmount: transaction.node.amount,
          description: isCustomerSupportDeposit(transaction.node.type)
            ? 'Customer Support'
            : `${transaction.node.createdBy?.givenName} ${transaction.node.createdBy?.familyName}`,
          id: transaction.node.transactionId,
          status: transaction.node.status,
          transactionDateTime: transaction.node.createdAt,
        },
      }
    default:
      return transaction
  }
}

export const ellipses = (text, length = 20) => truncate(text, { length })

export const getIconSizeFactor = ({
  size,
  width,
  height,
  defaultHeight,
  defaultWidth,
}) =>
  width || height
    ? (width || 0) / defaultWidth + (height || 0) / defaultHeight
    : size

export const getNumbersFromString = (str: string): number => {
  let num = str.match(/\d/g)
  return Number(num.join(''))
}

export const getMonthName = (date, locale = 'en') => {
  const partialDate = date.split('-')

  const formatedDate = DateTime.fromObject({
    // new Date(`${partialDate[0]}-01-${partialDate[1]}`).toISOString()
    day: 1,
    month: partialDate[0],
    year: partialDate[1],
  })
  return formatedDate.toLocaleString({ month: 'long' }, { locale })
}

export const groupTransactionsByMonths: any = (transactions: Array<any>) => {
  const formatedDate = (date) => DateTime.fromISO(date).toFormat('MM-yyyy')
  const availableDates = uniq(
    transactions.map((transaction) =>
      formatedDate(
        transaction?.node?.transactionDateTime || transaction?.node?.updatedAt
      )
    )
  )
  const getTransactionDate = (transaction) =>
    formatedDate(
      transaction?.node?.transactionDateTime || transaction?.node?.updatedAt
    )
  return groupBy(transactions, (transaction) =>
    availableDates.find((date) => date === getTransactionDate(transaction))
  )
}

export const getMinutesFromSeconds = (seconds) => {
  const minutes = `${Math.floor(seconds / 60)}`.padStart(2, '0')
  const rest = `${seconds % 60}`.padStart(2, '0')
  return minutes + ':' + rest
}

export const generateUUID = (prefix: string = '') => {
  const idGenerator =
    window.crypto?.randomUUID?.() || window?.self?.crypto?.randomUUID?.()
  return `${prefix}${idGenerator}`
}

export const makeRequest = async (options) => {
  return await API.graphql({
    ...options,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  })
}

export const getAmount = (billingAmount): number =>
  Number(`${billingAmount?.basicUnit || 0}.${billingAmount?.fraction || 0}`)

export const validatePhoneNumber = (num: string) => {
  if (num.indexOf('+') === 0) {
    return num
  } else {
    return `+${num}`
  }
}

export const normalizedCardName = (card: string) => {
  enum AvailableCards {
    Amex = 'amex',
    Visa = 'visa',
    Mastercard = 'mastercard',
    Generic = 'genericCard',
    Diner = 'diner',
  }
  if (card === 'American' || card === 'Express' || card === 'American Express')
    return AvailableCards.Amex
  const key =
    Object.keys(AvailableCards).find((currentCard) => currentCard === card) ||
    'Generic'
  return AvailableCards[key]
}

export const normalizeValidationErrorMessage = (
  token: TranslationToken,
  interpolation: { [key: string]: number | string }
) => {
  return JSON.stringify({
    token: token,
    interpolation: interpolation,
  })
}

export const checkSafari = () => {
  return (
    navigator.userAgent.toLowerCase().includes('safari') &&
    !navigator.userAgent.toLowerCase().includes('chrome')
  )
}

export const getTransactionDate = (transaction) => {
  let result

  if (transaction.__typename === 'TransactionEdge') {
    result = DateTime.fromISO(transaction?.node?.transactionDateTime)
  }

  if (transaction.__typename === 'HoldingAccountTransactionEdge') {
    result = DateTime.fromISO(transaction?.node?.createdAt)
  }

  return result
}

export const sortTransactionsByDate = (a, b) => {
  const dateA = getTransactionDate(a)
  const dateB = getTransactionDate(b)

  return dateA > dateB ? -1 : 1
}

export const getPromotion = (subscription): TranslationToken => {
  switch (subscription?.planKey) {
    case 'HeroUltimateMonthly':
      return 'FIRST_MONTH_FREE'
    default:
      return null
  }
}
