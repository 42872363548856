import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { cleanNullProps } from '../../../utils/functions'
import RadioField from '../fields/RadioField'
import Paragraph from '../../typography/Paragraph'
import Flex from '../../Flex'
import Button from '../../Button'

export type PickupLocationsFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  pickupLocations?: any[]
  back?: Function
}

const schema = Yup.object().shape({
  pickupLocation: Yup.string().required(),
})

const PickupLocationsForm: React.FC<PickupLocationsFormProps> = ({
  data = {},
  submit,
  loading,
  disabled,
  pickupLocations,
  back,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { pickupLocation } = data

  const defaultInitialValues = {
    pickupLocation,
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      {pickupLocations.map((pickUpPoint) => {
        return (
          <RadioField
            css={styles.field}
            label={
              <>
                <Paragraph css={styles.data}>{pickUpPoint.name}</Paragraph>
                <Paragraph css={styles.data}>{pickUpPoint.address}</Paragraph>
                <Paragraph
                  css={styles.data}
                >{`${pickUpPoint.city}, ${pickUpPoint.region}`}</Paragraph>
                <Paragraph
                  css={styles.data}
                >{`${pickUpPoint.country}, ${pickUpPoint.postalCode}`}</Paragraph>
              </>
            }
            name="pickupLocation"
            value={pickUpPoint.code}
            showBorder
          />
        )
      })}

      {!loading && (
        <FormFooter css={styles.footer}>
          <Flex gap="1rem" vertical>
            <FormButton token="NEXT" />
            <Button token="BACK" secondary onClick={back} />
          </Flex>
        </FormFooter>
      )}
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  headline: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  label: {
    color: TEXT_PRIMARY_DARK,
    fontSize: '14px',
    letterSpacing: '-.25px',
    lineHeight: '20px',
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
  data: {
    alignItems: 'center',
    color: TEXT_PRIMARY_DARK,
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.1px',
    lineHeight: '16px',
    margin: 0,
  },
})

export default PickupLocationsForm
