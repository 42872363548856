import React from 'react'
import { TPlainStyle } from '@emotion/react'
import 'yup-phone-lite'

import ApplicationRouter from './components/router/ApplicationRouter'
import SafeArea from './components/layout/SafeArea'
import OneSignalInit from './mobile/onesignal/pushNotifications'
import { getSafeArea, TInsets } from './mobile/safearea/safearea-config'
import { resetDoesManuallyLogout } from './mobile/biometric/biometric'
import Refresher from './mobile/appstate/Refresher'
import isMobile from './mobile/utils/isMobile'
import HeroToaster from './components/HeroToaster'

const App = () => {
  const [safeArea, setSafeArea] = React.useState<TInsets>()

  React.useEffect(() => {
    OneSignalInit()
    resetDoesManuallyLogout()
    ;(async () => {
      const { insets } = await getSafeArea()
      setSafeArea(insets)
    })()
  }, [])
  return (
    <>
      <Refresher enable={isMobile()} />
      <ApplicationRouter />

      <SafeArea>
        <div id="portal-default" css={styles.portal.default}></div>
        <div id="portal-modal" css={styles.portal.modal}></div>
        <div id="portal-tooltip" css={styles.portal.default}></div>
      </SafeArea>

      <HeroToaster safeArea={safeArea} />
    </>
  )
}

const styles: TPlainStyle = {
  portal: {
    default: {
      left: 0,
      position: 'absolute',
      top: 0,
      zIndex: 100,
    },

    modal: {
      left: 0,
      position: 'fixed',
      top: 0,
      zIndex: 101,
    },
  },
}

export default App
