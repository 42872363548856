import React from 'react'
import { useMutation } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'

import { updateSideKickSettings as updateSideKickSettingsMutation } from '../../../queries'
import useIsSidekick from '../../../hooks/useIsSidekick'
import useTeamMembers, { RoleType } from '../../../hooks/useTeamMembers'
import PermissionsForm from '../../../components/form/forms/PermissionsForm'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import NoSk from '../../../assets/svg/NoSk'
import Header from '../../../components/typography/Header'
import Paragraph from '../../../components/typography/Paragraph'

type PermissionsSceneProps = {
  title: JSX.Element
  subtitle: JSX.Element
  back: string
}

const PermissionsScene: React.FC<PermissionsSceneProps> = ({ ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const innerFormik = React.useRef(null)

  const { teamMembers, loading } = useTeamMembers(
    true,
    false,
    RoleType.SIDEKICK
  )

  const [updateSideKickSettings, { loading: processing }] = useMutation(
    updateSideKickSettingsMutation
  )

  const isSidekick = useIsSidekick()

  const hasTeamMembers = teamMembers?.length > 1

  const membersWithCredentials = teamMembers.filter((member) => {
    return member?.userProfile?.email?.indexOf?.('herofinancials.app') === -1
  })

  const handleFieldUpdate = async (name, value) => {
    const sk = teamMembers.find((sk) => sk?.userProfile?.id === name)
    const variables = {
      userId: name,
      canOrderCards: value,
      receivesEmail: sk?.settings.receivesEmail,
    }

    try {
      await updateSideKickSettings({ variables })
    } catch (err) {
      innerFormik.current?.resetForm?.()
    }
  }

  return (
    <CardLayoutLink
      loading={loading}
      processing={processing}
      processingLabel={<Text token={'SETTING_PERMISSION'} />}
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        {hasTeamMembers && (
          <PermissionsForm
            handleFieldUpdate={handleFieldUpdate}
            loading={loading}
            innerRef={innerFormik}
            disabled={isSidekick}
            teamMembers={membersWithCredentials}
          />
        )}
        {!hasTeamMembers && (
          <Flex center vertical gap="1rem">
            <NoSk />
            <Header css={styles.headline} token="NO_SIDEKICKS_YET" />
            <Paragraph
              css={styles.description}
              token="EMPTY_PERMISSIONS_DETAIL"
            />
          </Flex>
        )}
      </Flex>
    </CardLayoutLink>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY }: TTheme) => ({
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  headline: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  description: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '24px',
    margin: 0,
    textAlign: 'center',
  },
})

export default PermissionsScene
