import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import React from 'react'
import Button from '../../components/Button'
import FlowStepLayout from '../../components/flows/FlowStepLayout'

import SelectSidekickForm from '../../components/form/forms/SelectSidekickForm'
import Info from '../../components/Info'

import FlowContext from '../../context/FlowContext'

const SelectSidekickStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout>
      <SelectSidekickForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        fieldName="forUser"
        {...rest}
      />
      {rest?.teamMembers?.length === 0 && (
        <Info
          token={'ADD_PRODUCT_IN_ORDER_TO_ORDER_CARD'}
          actions={[
            <Button css={styles.link} token="ADD_PRODUCT" to="/products/new" />,
          ]}
        />
      )}
    </FlowStepLayout>
  )
}

const computeStyles: TComputeStyles = ({ LINK }: TTheme) => ({
  root: {},
  link: {
    background: 'none',
    color: LINK,
    padding: 0,
    width: 'min-content',
  },
})

export default SelectSidekickStep
