import React from 'react'
import { TComputeStyles, useTheme } from '@emotion/react'
import get from 'lodash/get'
import clsx from 'clsx'

import Flex from '../../Flex'
import FieldStatus from '../FieldStatus'
import OptionChevron from '../../drawers/options/OptionChevron'
import Grid from '../../Grid'
import Text from '../../typography/Text'
import DrawerCard, {
  DrawerCardProps,
} from '../../../components/drawers/DrawerCard'
import FormContext from '../../../context/FormContext'

export const SelectDrawerFieldContext = React.createContext({})
export type SelectDrawerFieldProps = DrawerCardProps & FieldProps

const SelectDrawerField: React.FC<SelectDrawerFieldProps> = ({
  label,
  name,
  result,
  children,
  required,
  className,
  disabled,
  ...rest
}) => {
  const formState = React.useContext<any>(FormContext)
  const [selected, setSelected] = React.useState(formState?.values?.[name])
  const [drawer, setDrawer] = React.useState(false)

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  const select = async (option) => {
    await setSelected(option)
    await setDrawer(false)

    if (name) formState?.setFieldValue(name, option)
  }

  const { theme, colors } = useTheme()
  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  const { errors, submitCount } = formState

  const error = get(errors, name)

  return (
    <SelectDrawerFieldContext.Provider value={{ select, selected }}>
      <Grid
        as="label"
        gap="0.6rem"
        css={styles.root}
        className={classNames}
        onClick={() => setDrawer(true)}
      >
        {label && (
          <span>
            {label}
            {required && '*'}
          </span>
        )}

        <Flex spread css={styles.fieldWrapper}>
          <Text>{result && result(selected)}</Text>
          <OptionChevron selected={drawer} />
        </Flex>
      </Grid>

      {Boolean(error && submitCount) && (
        <FieldStatus show={error && submitCount} status={error} />
      )}

      <DrawerCard
        isOpened={drawer}
        handleClickOutside={() => setDrawer(false)}
        {...rest}
      >
        {children}
      </DrawerCard>
    </SelectDrawerFieldContext.Provider>
  )
}

const computeStyles: TComputeStyles = ({
  PRIMARY_ALT,
  FIELD_BORDER,
  DISABLED,
  FIELD_DISABLED_BACKGROUND,
  BLACK_700,
}) => ({
  root: {
    position: 'relative',
    padding: '12px 16px',

    color: BLACK_700,
    fontSize: 13,
    fontWeight: 500,
    minHeight: 48,

    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: 16,

    '&.is-disabled': {
      color: DISABLED,
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      pointerEvents: 'none',
    },

    '&.is-loading': {
      color: DISABLED,
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      pointerEvents: 'none',
    },

    '&:focus-within': {
      border: `2px solid ${PRIMARY_ALT}`,
    },
  },

  fieldWrapper: {
    margin: '0px -16px 0px 0px',
    position: 'relative',
  },
})

export default SelectDrawerField
