import React from 'react'
import { TPlainStyle } from '@emotion/react'

import Field, { FieldProps } from '../Field'

type SelectFieldProps = {
  hideDefaultOption?: boolean
  placeholder?: string
} & FieldProps

const SelectField: React.FC<SelectFieldProps> = ({
  children,
  hideDefaultOption = false,
  placeholder = '-',
  ...rest
}) => {
  return (
    <Field as="select" css={styles.root} {...rest}>
      {!hideDefaultOption && <option>{placeholder}</option>}
      {children}
    </Field>
  )
}

const styles: TPlainStyle = {
  root: {
    '& select': {
      marginLeft: '-16px',
      marginRight: '-16px',
      padding: '0 16px',
    },
  },
}

export default SelectField
