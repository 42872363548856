import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import * as Yup from 'yup'

import FormButton from '../Button'
import ButtonGroup from '../../ButtonGroup'
import Form from '../Form'
import FormFooter from '../FormFooter'

import Text from '../../typography/Text'
import ProductOptionField from '../fields/ProductOptionField'

import CardType from '../../../utils/cardTypes'

type ChooseProductFormProps = {
  isInternational?: boolean
  submit?: Function
  loading?: boolean
  disabled?: boolean
  data?: object
}

const schema = Yup.object().shape({ product: Yup.string().required() })

const ChooseProductForm: React.FC<ChooseProductFormProps> = ({
  submit,
  loading,
  disabled,
  data,
  isInternational,
  ...rest
}) => {
  const { theme } = useTheme()

  const defaultInitialValues = {
    product: isInternational
      ? CardType.SideKickInternational
      : CardType.SideKickCanada,
  }

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ ...defaultInitialValues, ...data }}
      {...rest}
    >
      {({ values }) => (
        <>
          <ProductOptionField
            css={styles.field}
            disabled={isInternational}
            name="product"
            value={CardType.SideKickCanada}
          />
          <ProductOptionField
            disabled={!isInternational}
            css={styles.field}
            type="international"
            name="product"
            value={CardType.SideKickInternational}
          />
          <FormFooter>
            <ButtonGroup>
              <FormButton
                loading={loading}
                label={
                  <Text
                    token={values.product ? 'ADD_PRODUCT' : 'SELECT_A_PRODUCT'}
                  />
                }
                disabled={!values.product}
              />
            </ButtonGroup>
          </FormFooter>
        </>
      )}
    </Form>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default ChooseProductForm
