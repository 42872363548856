import { useQuery } from '@apollo/client'
import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import PickupLocationsForm from '../../components/form/forms/PickupLocationsForm'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'
import { findPickupLocations, teamMembers } from '../../queries'

const PickupPointsStep = ({ ...rest }) => {
  const { currentData, next, setFlowData, prev } = React.useContext(FlowContext)
  const { data: teamMembersData } = useQuery(teamMembers)
  const sk = teamMembersData.teamMembers.find(
    (acc) => acc.userProfile?.id === currentData?.data?.forUser
  )
  const { data: pickupLocationsData, loading } = useQuery(findPickupLocations)

  const { pickupLocations = [] } = pickupLocationsData || {}

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  return (
    <FlowStepLayout loading={loading}>
      <Text token="THE_FOLLOWING_LOCATIONS" />
      <PickupLocationsForm
        submit={handleSubmit}
        data={{
          shippingAddress: sk?.userProfile?.principalAddress || {},
          ...currentData?.data,
        }}
        pickupLocations={pickupLocations}
        loading={loading}
        back={prev}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default PickupPointsStep
