import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Form from '../Form'
import DeliveryMethodOptionField from '../fields/DeliveryMethodOptionField'
import { DELIVERY_METHOD } from './ChooseCardInternationalDeliveryMethodForm'

type ChooseCardDomesticDeliveryMethodFormProps = {
  submit?: Function
  handleFieldUpdate?: Function
  loading?: boolean
  disabled?: boolean
  data?: object
}

const schema = Yup.object().shape({ deliveryMethod: Yup.string().required() })

const defaultInitialValues = { deliveryMethod: DELIVERY_METHOD.DELIVERY }

const ChooseCardDomesticDeliveryMethodForm: React.FC<
  ChooseCardDomesticDeliveryMethodFormProps
> = ({ submit, loading, disabled, data, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ ...defaultInitialValues, ...data }}
      {...rest}
    >
      <DeliveryMethodOptionField
        css={styles.field}
        value={DELIVERY_METHOD.DELIVERY}
        name="deliveryMethod"
      />
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  DISABLED_BACKGROUND,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
    '&.is-disabled.is-radio': {
      backgroundColor: DISABLED_BACKGROUND,
    },
  },
})

export default ChooseCardDomesticDeliveryMethodForm
