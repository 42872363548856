import React from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'

import * as Yup from 'yup'
import get from 'lodash/get'
import clsx from 'clsx'

import countries from '../../../utils/countries'
import Flex from '../../Flex'
import Grid from '../../Grid'
import Icon from '../../Icon'
import Dropdown, {
  DropdownContext,
  DropdownOutlet,
  DropdownTrigger,
} from '../../Dropdown'
import List from '../../lists/List'
import ListItem from '../../lists/ListItem'
import Text from '../../typography/Text'
import FormContext from '../../../context/FormContext'
import FieldStatus from '../FieldStatus'

type PhoneFieldProps = {
  className?: string
  setFieldValue?: Function
  name?: string
  disabled?: boolean
  loading?: boolean
  label?: string | JSX.Element
  error?: string | JSX.Element
  defaultValue?: string
  required?: boolean
  onChange?: Function
}

export const PHONE_VALIDATOR = Yup.string().phone(
  'CA',
  'ENTER_VALID_PHONE_NUMBER'
)
export const PHONE_VALIDATOR_REQUIRED = PHONE_VALIDATOR.required(
  'ENTER_VALID_PHONE_NUMBER'
)

const CountryList = ({ styles, handleSelectCountry }) => {
  const { setOpen } = React.useContext(DropdownContext)

  return (
    <List css={styles.countriesList}>
      {countries.map((country) => {
        return (
          <ListItem
            css={styles.countriesListItemWrapper}
            key={`Country_${country.abbreviation}_${country.code}`}
          >
            <Flex
              css={styles.countriesListItem}
              spread
              onClick={() => handleSelectCountry(country, () => setOpen(false))}
            >
              <Flex centerY>
                <img
                  css={styles.flag}
                  alt={country.abbreviation}
                  width={25}
                  height={20}
                  src={country.flag}
                />

                {country.country}
              </Flex>
              <Flex centerY css={styles.listCode}>{`+${country.code}`}</Flex>
            </Flex>
          </ListItem>
        )
      })}
    </List>
  )
}

const CountriesButton = ({ styles, selectedCountry, classNames }) => {
  const { open } = React.useContext(DropdownContext)
  const chevronClassNames = clsx({
    'is-open': open,
  })
  return (
    <Flex centerY spread css={styles.dropdownToggle} className={classNames}>
      <img
        alt={selectedCountry.country}
        width={25}
        height={20}
        src={selectedCountry.flag}
      />
      <span css={styles.dropdownToggleLabel}>
        {selectedCountry.abbreviation}
      </span>
      <Icon
        icon="chevron"
        css={styles.dropdownChevron}
        className={chevronClassNames}
      />
    </Flex>
  )
}

const PhoneField: React.FC<PhoneFieldProps> = ({
  className,
  setFieldValue,
  name,
  disabled,
  loading,
  label,
  required,
  onChange,
  defaultValue,
  error,
  ...rest
}) => {
  const ref = React.useRef(null)
  const form = React.useContext<any>(FormContext)

  const { theme, sizes } = useTheme()
  const [styles, setStyles] = React.useState(
    computeStyles(theme, sizes, ref?.current?.offsetWidth)
  )

  const classNames = clsx({
    [className]: className,
    'is-disabled': form.disabled || disabled,
    'is-loading': form.loading || loading,
  })

  const [selectedCountry, setSelectedCountry] = React.useState(
    countries.find((c) => c.country === 'Canada')
  )

  React.useEffect(() => {
    if (ref.current?.offsetWidth > 0)
      setStyles(computeStyles(theme, sizes, ref?.current?.offsetWidth))
  }, [theme, ref.current?.offsetWidth])

  const fieldClassNames = clsx({
    'is-disabled': form.disabled || disabled,
    'is-loading': form.loading || loading,
  })

  const prefixClassNames = clsx({
    'is-field-disabled': form.disabled || disabled,
    'is-field-loading': form.loading || loading,
  })

  const handleSelectCountry = (country, close) => {
    setSelectedCountry(country)
    close()
  }

  const handleChange = async (e) => {
    const phone = `+${selectedCountry.code}${e.target.value.replaceAll(
      /\D+/g,
      ''
    )}`
    await form?.setFieldValue(name, phone)
    await form?.handleFieldUpdate(name, phone)
    onChange?.(name, phone)
  }

  const handlePaste = async (e) => {
    const pasted = e.clipboardData?.getData?.('text/plain')
    const cleaned = pasted?.replaceAll?.(/\D+/g, '')
    const withoutCountryCode = cleaned?.substring(selectedCountry.code.length)

    e.target.value = withoutCountryCode
    handleChange(e)
    e.preventDefault()
  }

  const handleKeyDown = (e) => {
    const numRegex = /[0-9]/
    if (!numRegex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter')
      e.preventDefault()
  }

  const initialValue = (form.values?.[name] || defaultValue)?.replace?.(
    `+${selectedCountry.code}`,
    ''
  )

  const submitCount = form?.submitCount

  const errorMsg = get(form?.errors, [name]) || error

  return (
    <>
      <Dropdown>
        <Grid css={styles.root} innerRef={ref} className={classNames}>
          <DropdownTrigger>
            <CountriesButton
              selectedCountry={selectedCountry}
              styles={styles}
              classNames={fieldClassNames}
            />
          </DropdownTrigger>
          <DropdownOutlet>
            <CountryList
              styles={styles}
              handleSelectCountry={handleSelectCountry}
            />
          </DropdownOutlet>
          <Flex vertical css={styles.content} gap={sizes.FIELD_GAP}>
            {label && (
              <Flex centerY css={styles.placeholder}>
                {label}
                {required && '*'}
              </Flex>
            )}
            <Flex nowrap>
              <Text
                css={styles.prefix}
                className={prefixClassNames}
              >{`(+${selectedCountry?.code})`}</Text>
              <input
                css={styles.field}
                className={fieldClassNames}
                onChange={handleChange}
                onKeyPress={handleKeyDown}
                onPasteCapture={handlePaste}
                defaultValue={initialValue}
              />
            </Flex>
          </Flex>
        </Grid>
        <FieldStatus show={!!errorMsg && submitCount > 0} status={errorMsg} />
      </Dropdown>
    </>
  )
}

const computeStyles: TComputeStyles = (
  {
    BACKGROUND_ALT,
    BACKGROUND,
    DISABLED,
    FIELD_BORDER,
    FIELD_DISABLED_BACKGROUND_DARK,
    FIELD_DISABLED_BACKGROUND,
    PRIMARY_ALT,
    TEXT_PRIMARY_DARK,
    TEXT_PRIMARY_LIGHT,
    TEXT_PRIMARY,
  }: TTheme,
  {
    FIELD_PADDING,
    FIELD_FONT_SIZE,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
  }: TSizes,
  listWidth = 100
) => ({
  root: {
    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: FIELD_BORDER_RADIUS,
    gridTemplateColumns: '106px 1fr',
    overflow: 'hidden',
    '&:focus-within': {
      border: `2px solid ${PRIMARY_ALT}`,
    },
    '&.is-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&.is-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },
  },
  dropdownToggle: {
    backgroundColor: BACKGROUND,
    border: 'none',
    borderRadius: 0,
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: FIELD_PADDING,
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'transparent',
    },
    '&.is-error': {
      borderBottom: `1px solid ${FIELD_BORDER}`,
    },
    '&.is-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND_DARK,
      color: DISABLED,
    },

    '&.is-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND_DARK,
      color: DISABLED,
    },
  },
  dropdownToggleLabel: {
    color: TEXT_PRIMARY,
  },
  dropdownChevron: {
    transform: 'rotate(90deg)',
    '&.is-open': {
      transform: 'rotate(-90deg)',
    },
  },
  countriesList: {
    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: '8px',
    boxSizing: 'border-box',
    maxHeight: '380px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    width: listWidth,
  },
  countriesListItem: {
    background: BACKGROUND_ALT,
    borderBottom: `2px solid ${FIELD_BORDER}`,
    boxSizing: 'border-box',
    color: TEXT_PRIMARY,
    cursorPointer: 'pointer',
    margin: 0,
    maxWidth: '1136px',
    padding: '8px',
    width: listWidth - 2,
    '&:hover': {
      background: BACKGROUND,
    },
  },
  countriesListItemWrapper: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    width: listWidth,
  },
  listCode: {
    paddingRight: '16px',
  },
  flag: {
    marginRight: '8px',
  },
  content: {
    borderLeft: `1px solid ${FIELD_BORDER}`,
    padding: FIELD_PADDING,
  },
  placeholder: {
    color: TEXT_PRIMARY_LIGHT,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
  },
  prefix: {
    alignItems: 'center',
    background: BACKGROUND_ALT,
    color: TEXT_PRIMARY_LIGHT,
    display: 'flex',
    fontSize: FIELD_FONT_SIZE + 1,
    justifyContent: 'center',
    padding: '0 8px 0 0',
    '&.is-field-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
    },

    '&.is-field-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
    },
  },
  field: {
    border: 'none',
    borderRadius: 0,
    color: TEXT_PRIMARY_DARK,
    font: 'inter',
    fontSize: FIELD_FONT_SIZE,
    padding: '0',
    width: '100%',
    '&:focus-within': {
      border: 'none',
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '&.is-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
    },

    '&.is-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
    },
  },
})

export default PhoneField
