import React from 'react'

import Flex from '../../Flex'
import IconCircle from '../../IconCircle'
import Text, { TranslationToken } from '../../typography/Text'
import ListItem, { ListItemProps } from '../ListItem'
import { IconProps } from '../../Icon'
import { ColorType } from '../../../utils/themes'

type Props = {
  label?: string | JSX.Element
  token?: TranslationToken
  scale?: number
  iconColor?: ColorType | string
} & ListItemProps &
  IconProps

const BigMenuItem: React.FC<Props> = ({
  label,
  color = 'BLUE_300',
  icon = 'cog',
  token,
  scale,
  iconColor,
  loading,
  ...rest
}) => {
  return (
    <ListItem centerY gap="1rem" variants={animation.root} {...rest}>
      <IconCircle
        icon={icon}
        color={color}
        size={40}
        scale={scale}
        iconColor={iconColor}
        variants={animation.icon}
      />

      <Flex stretch variants={animation.text}>
        <Text token={token} css={styles.label}>
          {label}
        </Text>
      </Flex>
    </ListItem>
  )
}

const styles = {
  label: {
    fontSize: 16,
    fontWeight: '500',
  },
}

const animation = {
  root: {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0 },
  },
  icon: {
    hidden: { scale: 0 },
    show: {
      scale: 1,
      transition: { duration: 0.6, type: 'spring', bounce: 0.4 },
    },
    exit: { scale: 0 },
  },
  text: {
    hidden: { scaleX: 0.9 },
    show: {
      scaleX: 1,
      transition: { type: 'spring', duration: 0.5, bounce: 0.4 },
    },
    exit: { opacity: 0 },
  },
}

export default BigMenuItem
