import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import Text, { TextProps } from './typography/Text'

export type PillVariants =
  | 'error'
  | 'success'
  | 'warning'
  | 'primary-alt'
  | 'warning-alt'
  | 'promo'

export type PillProps = {
  customColor?: string
  variant?: PillVariants
  className?: string
} & React.PropsWithChildren &
  Partial<TextProps>

const Pill: React.FC<PillProps> = ({
  children,
  variant,
  customColor,
  className,
  ...rest
}) => {
  const { theme } = useTheme()

  const classNames = clsx({
    [className]: className,
    'is-success': variant === 'success',
    'is-error': variant === 'error',
    'is-warning': variant === 'warning',
    'is-warning-alt': variant === 'warning-alt',
    'is-primary-alt': variant === 'primary-alt',
    'is-promo': variant === 'promo',
  })

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Text capitalize={false} css={styles.root} className={classNames} {...rest}>
      {children && children}
    </Text>
  )
}

const computeStyles: TComputeStyles = ({
  PILL_DEFAULT_BACKGROUND,
  PROMOTION_PILL_TEXT_COLOR,
  TEXT_PRIMARY_DARK,
  PILL_PRIMARY_ALT,
  SUCCESS,
  ERROR,
  WARNING,
  SECONDARY_ALT,
}: TTheme) => ({
  root: {
    backgroundColor: PILL_DEFAULT_BACKGROUND,
    borderRadius: 16,
    color: TEXT_PRIMARY_DARK,
    display: 'inline-block',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '14px',
    padding: '5px 10px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '&.is-primary-alt': {
      backgroundColor: PILL_PRIMARY_ALT,
    },
    '&.is-promo': {
      backgroundColor: PILL_PRIMARY_ALT,
      color: PROMOTION_PILL_TEXT_COLOR,
    },
    '&.is-success': {
      backgroundColor: SUCCESS,
    },
    '&.is-error': {
      backgroundColor: ERROR,
    },
    '&.is-warning': {
      backgroundColor: WARNING,
    },
    '&.is-warning-alt': {
      backgroundColor: SECONDARY_ALT,
    },
  },
})

export default Pill
