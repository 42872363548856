import React from 'react'
import clsx from 'clsx'
import { TPlainStyle } from '@emotion/react'

import Flex from '../../Flex'
import Icon from '../../Icon'

const OptionChevron = ({ selected, className, ...rest }) => {
  const classNames = clsx({
    [className]: className,
    'is-selected': selected,
  })

  return (
    <Flex center css={styles.root}>
      <Icon
        icon="chevron"
        size={1}
        css={styles.root}
        className={classNames}
        {...rest}
      />
    </Flex>
  )
}

const styles: TPlainStyle = {
  root: {
    width: 45,
    height: 22,
    transform: 'rotate(45deg)',

    '&.is-selected': {
      transform: 'rotate(-135deg)',
    },
  },
}

export default OptionChevron
