import React from 'react'
import { useSelector } from 'react-redux'

import ConfirmModal from '../../../../../components/modals/ConfirmModal'
import FlowCardLayout, {
  FlowStep,
} from '../../../../../components/layout/FlowCardLayout'

import SelectMemberTypeStep from './select-member-type'
import PromptMemberInformationStep from './prompt-member-information'

import usePrompt from '../../../../../hooks/usePrompt'
import Text from '../../../../../components/typography/Text'
import { FlowStateType } from '../../../../../store/flow'

const AddTeamMember = ({ ...rest }) => {
  const flowState = useSelector(
    (state: FlowStateType) => state.flow.addTeamMember
  )
  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)

  React.useEffect(() => {
    if (flowState?.meta.keepDirty) return next()
    if (flag) setShowConfirm(flag)
  }, [flag])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  return (
    <>
      <FlowCardLayout
        name="addTeamMember"
        cancel="/settings/team"
        to="/settings/team?refresh"
        {...rest}
      >
        <FlowStep
          title={<Text token="ADD_TEAM_MEMBER_INFO" />}
          element={SelectMemberTypeStep}
        />
        <FlowStep
          title={<Text token="COMPLETE_MEMBER_INFORMATION" />}
          element={PromptMemberInformationStep}
        />
      </FlowCardLayout>

      {showConfirm && (
        <ConfirmModal
          title={<Text token="CONFIRM" />}
          content={<Text token="DATA_LOSS_CONFIRM_MESSAGE" />}
          confirm={next}
          cancel={closeModal}
          backdrop
        />
      )}
    </>
  )
}

export default AddTeamMember
