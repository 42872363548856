import React from 'react'
import { useTheme } from '@emotion/react'
import { generateUUID } from '../../../utils/functions'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import Actions from '../../Actions'
import Button from '../../Button'
import Flex from '../../Flex'
import Text from '../../typography/Text'
import Icon from '../../Icon'
import Grid from '../../Grid'
import FormContext from '../../../context/FormContext'
import FieldStatus from '../FieldStatus'
import ConfirmModal from '../../modals/ConfirmModal'

const SIZE_16_MB = 16777216

const verifySize = (files: File[]) =>
  isEmpty(files.filter((file: File) => file.size > SIZE_16_MB))

const FileItem = ({ file, onRemove, loading }) => {
  const { theme } = useTheme()

  const styles = computeItemStyles(theme)

  return (
    <Flex spread padding="1rem" rounded css={styles.root}>
      <Text>{file.model?.name}</Text>

      {!loading && (
        <Flex onClick={onRemove}>
          <Icon icon="times" />
        </Flex>
      )}
    </Flex>
  )
}

const FilesField = ({ name, disabled, ...rest }) => {
  const { setFieldValue, errors, submitCount } = React.useContext(FormContext)
  const [files, setFiles] = React.useState([])
  const fileInputRef = React.useRef()
  const [sizeError, setSizeError] = React.useState(false)

  const error = get(errors, name)

  React.useEffect(() => {
    setFieldValue(name, files)
  }, [files])

  const handleAddFile = () => fileInputRef.current?.click()

  const handleFileAdded = (event) => {
    const files: File[] = Array.from(event.target.files)

    if (!verifySize(files)) {
      setSizeError(true)
      return
    }

    const newFiles = files?.map((file) => ({
      model: file,
      id: generateUUID(),
    }))

    setFiles((files) => [...files, ...newFiles])

    event.target.value = null
  }

  const handleFileRemoval = (file) => {
    setFiles((currentFiles) =>
      currentFiles.filter((currentFile) => currentFile.id !== file.id)
    )
  }

  return (
    <Grid gap="1rem">
      <input
        id="file"
        ref={fileInputRef}
        name="profile"
        type="file"
        onChange={handleFileAdded}
        hidden
        {...rest}
      />

      <Actions>
        <Button
          token="ADD_FILES"
          icon="upload"
          size="1"
          onClick={handleAddFile}
          disabled={disabled}
        />
      </Actions>

      {files.length > 0 && (
        <Grid>
          <Grid gap="8px">
            {files.map((file) => (
              <FileItem file={file} onRemove={() => handleFileRemoval(file)} />
            ))}
          </Grid>
        </Grid>
      )}

      <FieldStatus show={Boolean(error && submitCount)} status={error} />

      <ConfirmModal
        title="SIZE_LIMIT_EXCEEDED"
        content="SIZE_LIMIT_MESSAGE"
        confirm={() => setSizeError(false)}
        handleClickOutside={() => setSizeError(false)}
        isOpened={sizeError}
        confirmLabel="OK"
      />
    </Grid>
  )
}

const computeItemStyles = ({ FILE_ITEM_BG }) => ({
  root: {
    backgroundColor: FILE_ITEM_BG,
  },
})

export default FilesField
